import { ActionDataType } from "../components/action/Action";
import { NewsSectionDataType } from "../components/views/home/news-section/NewsSection";
import { VideoDataType } from "../components/video/Video";
import { StaticBannerDataType } from "../components/staticbanner/StaticBanner";
import { FeedDataType } from "../components/views/home/feedsection/feed-section";
import { SignupDataType } from "../components/signup/Signup";

// type define
type MockType = {
  video: VideoDataType;
  action: ActionDataType;
  news: NewsSectionDataType;
  creator: StaticBannerDataType;
  feed: FeedDataType;
  newsletter: SignupDataType;
};

export const Home_Mock_Data: MockType = {
  video: {
    videoUrl: "videos/test-vid.mp4",
    storeLeftIcon: { url: "images/header/app-store-logo.png" },
    storeLeftUrl: "https://apps.apple.com/us/app/gangstar-vegas/id571393580",
    storeRightIcon: { url: "images/header/google-play-logo.png" },
    storeRightUrl: "https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftGGHM",
  },
  action: {
    headerTitleDesktop: "Enjoy the action",
    headerTitleMobile: `            
      <p>Enjoy</p>
      <p>the action</p>`,
    headerDescription: "RUN FREE IN A MASSIVE OPEN WORLD GAME FULL OF GANG WARS, THEFT, VICE, AUTO RACING & SHOOTING ACTION!",
    footerTitleDesktop: "About the game",
    footerTitleMobile: `            
      <p>About</p>
      <p>The Game</p>`,
    footerDescription: `Sandbox adventure across Vegas with buffed TPS and driving
      simulation, great story with lots of missions & practically infinite
      collection of any kind of fancied vehicles, weapons, clothes!`,
    buttonText: "Read more",
    buttonUrl: "/about",
    cards: [
      {
        image: { url: "images/action/action-new-level.jpg" },
        title: "new level of open-world",
        description: "The renowned Gangstar Vegas, flagman of the series, brings intense open-world action on a new level!",
      },
      {
        image: { url: "images/action/action-mission-is-calling.jpg" },
        title: "Big Vegas Adventure",
        description: "Take part in a sandbox adventure across Vegas with buffed TPS and driving simulation",
      },
      {
        image: { url: "images/action/action-big-vegas.jpg" },
        title: "Mission is calling you",
        description: "Great story with lots of missions & practically infinite collection of any kind of fancied vehicles, weapons, clothes!",
      },
    ],
  },
  creator: {
    title: "BECOME A PARTNER",
    description: "Become an influencer & content creator with Gangstar Vegas!",
    buttonText: "LEARN MORE",
    buttonUrl: "/content-creator",
    character: { url: "images/content-creator/banner/Jason.jpg" },
    background: { url: "images/content-creator/banner/background.jpg" },
  },
  news: {
    title: "gangstar news",
    desciption: "G-PRESS: THE LATEST NEWS AND ANNOUNCEMENTS",
    buttonText: "MORE NEWS",
    buttonLink: "/community",
    background: { url: "images/news/background.png" },
  },
  feed: {
    title: "NEWSFEED",
    description: "FRESHEST NEWS AND LATEST UPDATES",
  },
  newsletter: {
    title: "STAY TUNED",
    description: "SIGN UP TODAY TO RECEIVE THE LATEST GANGSTAR VEGAS NEWS, UPDATES, BEHIND-THE-SCENES CONTENT, EXCLUSIVE OFFERS, AND MORE. WE DON'T SPAM.",
    name: "FIRST NAME",
    nameError: "PLEASE ENTER YOUR FIRST NAME",
    lastname: "LAST NAME",
    lastnameError: "PLEASE ENTER YOUR LAST NAME",
    email: "EMAIL",
    emailError: "PLEASE ENTER YOUR EMAIL",
    birthdate: "BIRTHDATE",
    birthdateError: "PLEASE ENTER YOUR DATE OF BIRTH",
    platform: ["IOS", "ANDROID", "WINDOWS", "OTHER"],
    newsletterAgreement: "I agree to receive information and promotional offers relating to Gangstar Vegas.",
    newsletterGameloft: "I agree to receive information and promotional offers relating to Gameloft.",
    term: `I agree to the Gameloft <a href="https://www.gameloft.com/en/conditions-of-use" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and I have read the <a href="https://www.gameloft.com/en/privacy-notice" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.`,
    termError: "YOU MUST AGREE TO THE TERMS AND CONDITIONS AND READ THE PRIVACY POLICY.",
    buttonText: "SIGN UP",
  },
};
