import EActionTypes from "../actions/actionTypes";
import { NavAction } from "../actions/navAction";

export enum BackTo {
  NEWS = "NEWS",
  HOME = "HOME"
}

export interface INavState {
  needScrollBack: boolean;
  backTo: BackTo;
}

const initialState: INavState = {
  needScrollBack: false,
  backTo: BackTo.HOME
};

export const navReducer = (
  state: INavState = initialState,
  action: NavAction
): INavState => {
  switch (action.type) {
    case EActionTypes.SWITCH_SCROLL_BACK_STATUS:
      return {
        ...state,
        needScrollBack: action.payload
      };
    case EActionTypes.UPDATE_BACK_TO_LOCATION:
      return {
        ...state,
        backTo: (action.payload as unknown) as BackTo
      };
    default:
      return state;
  }
};
