import * as React from "react";
import "./CustomSelect.scss";
import { join } from "lodash-es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export interface CustomSelectProps {
  label?: string;
  inputRef?: (ref: any) => void;
  classes?: {
    root?: string;
    label?: string;
    select?: string;
  };
}

const CustomSelect: React.FC<CustomSelectProps & React.HTMLProps<HTMLSelectElement>> = ({ label, classes, inputRef, ...props }) => {
  return (
    <div className={join(["com-custom-select", classes?.root], " ")}>
      <label htmlFor={props.id} className={join(["com-custom-select__label", classes?.label], " ")}>
        {label || ""}
      </label>
      <select className={join(["com-custom-select__select", classes?.select], " ")} ref={inputRef} {...props}>
        {props.children}
      </select>
      <FontAwesomeIcon className="com-custom-select__arrow" icon={faChevronDown} />
    </div>
  );
};

export default CustomSelect;
