import * as React from "react";
import "./AgeGate.scss";
import GameIcon from "../../assets/images/header/logo-gsv.png";
import { useDispatch } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { switchViewMode, ViewMode } from "../../redux/actions/viewAction";
import { TriggerPoint } from "../../utils";

export interface AgeGateProps {}

const AgeGate: React.FC<AgeGateProps> = () => {
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [displayWarning, setDisplayWarning] = useState<boolean>(true);
  const [displayGate, setDisplayGate] = useState<boolean>(true);
  const [age, setAge] = useState<number>();
  const dispatch = useDispatch();
  const handleAgeValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.search(/[0-9]*$/g)) {
      e.currentTarget.value = "";
    }
    if (e.currentTarget.value === "") {
      setDisplayWarning(true);
      setDisplayError(false);
    } else if (parseInt(e.currentTarget.value) < 18) {
      setDisplayError(true);
      setDisplayWarning(false);
    } else {
      setDisplayError(false);
      setDisplayWarning(false);
    }
    setAge(parseInt(e.target.value));
  };

  const disableAgeGate = useCallback(() => {
    setDisplayGate(false);
    dispatch(switchViewMode(ViewMode.NORMAL, TriggerPoint.HOME));
    localStorage.setItem("ageGate", "pass");
  }, [dispatch]);

  const handleConfirmButton = () => {
    if (age) {
      if (age >= 18) {
        disableAgeGate();
        window.location.reload();
      }
    }
  };

  //Handle Enter key press
  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        if (age && age >= 18) {
          if (displayGate) {
            disableAgeGate();
            window.location.reload();
          }
        }
      }
    };
    window.addEventListener("keydown", (e) => {
      handleEnter(e as any);
    });

    return () => {
      window.removeEventListener("keydown", (e) => {
        handleEnter(e as any);
      });
    };
  });

  //Check local storage to see if age gate has been disabled
  useEffect(() => {
    if (localStorage.getItem("ageGate") === "pass") {
      disableAgeGate();
    }
  }, [disableAgeGate]);

  return (
    <div className={"com-age-gate" + (displayGate ? "" : " hide")}>
      <div className="container">
        <img src={GameIcon} alt="Gangstar Vegas" className="game-icon" />
        <label htmlFor="age-text-box">CONFIRM YOUR AGE</label>
        <input
          autoComplete="off"
          id="age-text-box"
          type="text"
          className="age-text-box"
          maxLength={2}
          onChange={(e) => {
            handleAgeValidation(e);
          }}
        />
        <div className="text-warning">
          <span className={"warning-message" + (displayWarning ? "" : " hide")}>You must be at least 18 to enter this site</span>
          <div className={"error-message" + (displayError ? "" : " hide")}>
            <p>We’re sorry but Gangstar Vegas is for mature audiences!</p>
            <p>We’ll be waiting for you to come back when you’re 18!</p>
          </div>
        </div>
        <button onClick={handleConfirmButton} className="confirm-button">
          CONFIRM
        </button>
      </div>
    </div>
  );
};

export default AgeGate;
