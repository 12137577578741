import EActionTypes, { IBaseAction } from "./actionTypes";
import { TriggerPoint } from "../../utils";

export interface IViewSwitchAction extends IBaseAction {
  type: EActionTypes;
  location: TriggerPoint;
  payload: ViewMode;
}

export interface IViewUpdateIndexAction extends IBaseAction {
  type: EActionTypes;
  location: TriggerPoint;
  payload: number | null;
}

export enum ViewMode {
  NORMAL = "NORMAL",
  LARGE = "LARGE",
  STOP_SCROLL = "STOP_SCROLL"
}

export type ViewAction = IViewSwitchAction | IViewUpdateIndexAction;

export const switchViewMode = (mode: ViewMode, location: TriggerPoint) => {
  return {
    type: EActionTypes.SWITCH_VIEW,
    location: location,
    payload: mode
  };
};

export const updateSlideIndex = (index: number, location: TriggerPoint) => {
  return {
    type: EActionTypes.UPDATE_SLIDE_INDEX,
    location: location,
    payload: index
  };
};

export const updateInViewSlideIndex = (
  index: number,
  location: TriggerPoint
) => {
  return {
    type: EActionTypes.UPDATE_IN_VIEW_SLIDE_INDEX,
    location: location,
    payload: index
  };
};

export const updateID = (id: string, location: TriggerPoint) => {
  return {
    type: EActionTypes.UPDATE_SLIDE_ID,
    location: location,
    payload: id
  };
};
