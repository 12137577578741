import React, { useState, useEffect, useCallback } from "react";
import "./LiveFeed.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import LiveFeedCard, { LiveFeedCardProps } from "./livefeedcard/LiveFeedCard";
import { highlights, isDefined, TriggerPoint } from "../../utils";
import { NavLink, useLocation } from "react-router-dom";
import MasonryGrid from "./masonrygrid/MasonryGrid";
import ScrollMenu from "../scrollmenu/ScrollMenu";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { IViewState } from "../../redux/reducers/viewReducer";
import { switchViewMode, updateInViewSlideIndex, ViewMode } from "../../redux/actions/viewAction";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FadeIn from "../fadein/FadeIn";
import { IFetchState } from "../../redux/reducers/fetchReducer";
import { fetchFeedData } from "../../redux/actions/fetchAction";
import { ReactComponent as LoadingSpin } from "../../assets/images/loading.svg";
import LazyImage from "../../libs/lazy-load/LazyImage";

interface IDictionary {
  [key: string]: string;
}

export interface LiveFeedProps {
  isPage?: boolean;
  showInstagram?: boolean;
  showDiscord?: boolean;
  showFacebook?: boolean;
  showYoutube?: boolean;
}

const LiveFeed: React.FC<LiveFeedProps> = (props) => {
  const dispatch = useDispatch();
  const [feedPool, setFeedPool] = useState<Array<LiveFeedCardProps>>();
  const [snsFilter, setSnsFilter] = useState<string>("all");
  const [searchResult, setSearchResult] = useState<Array<LiveFeedCardProps>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [viewerCardPool, setViewerCardPool] = useState<Array<LiveFeedCardProps>>();
  const [displayLoading, setDisplayLoading] = useState<boolean>(true);
  const feedFetcher = useSelector<StoreState, IFetchState>((state) => state.feedData);
  const searchRef = React.createRef<HTMLInputElement>();
  const liveFeedViewRef = React.createRef<HTMLDivElement>();
  const location = useLocation();
  const initialIconState: IDictionary = {
    discord: "#ffffff",
    facebook: "#ffffff",
    instagram: "#ffffff",
    youtube: "#ffffff",
    all: "#ffffff",
  };
  const embiggenSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerPadding: "0px",
        },
      },
    ],
  };
  const [goToSlide, setGoToSlide] = useState<number>();
  const [snsIconColor, setSnsIconColor] = useState<IDictionary>({
    ...initialIconState,
    all: "#fabf49",
  });
  const view = useSelector<StoreState, IViewState>((state) => state.liveFeedView);

  // const header = props.isPage ? (
  //   <div className="header">
  //     <p className="title-for-page">STORIES</p>
  //   </div>
  // ) : (
  //   <div className="header">
  //     <p className="title">NEWSFEED</p>
  //     <p className="description">FRESHEST NEWS AND LATEST UPDATES</p>
  //   </div>
  // );

  //Change color of the SNS filter onclick
  const highlightActiveFilter = (elementID: string) => {
    let tempIconState = { ...initialIconState };
    tempIconState[elementID] = "#fabf49";
    setSnsIconColor(tempIconState);
  };

  //Search for feed card that have contains searchString
  //Return array
  const searchForFeed = (pool: Array<LiveFeedCardProps>, searchString: string | undefined) => {
    if (searchString === "") {
      return pool;
    } else {
      return pool
        .map((card, i) => {
          if (card && searchString) {
            if (card.text.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) > -1) {
              return { ...card, index: i };
            }
          }
          return undefined;
        })
        .filter(isDefined);
    }
  };

  //Load more feeds when click on More Feeds button
  const loadMoreFeed = (currentFilter: string) => {
    dispatch(fetchFeedData(currentFilter, true, feedFetcher.appendOffset));
  };

  //Load feeds base on SNS filter
  const handleSNSFilter = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (snsFilter !== e.currentTarget.id) {
      highlightActiveFilter(e.currentTarget.id);
      setSnsFilter(e.currentTarget.id);
      dispatch(fetchFeedData(e.currentTarget.id));
    }
  };

  //Handle search when input texts into search box
  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  //Handle more feeds button click event
  //Go to /feed if button is in home page
  //Load more feed if it is in /feed
  const handleMoreFeedBtn = () => {
    loadMoreFeed(snsFilter);
  };

  //Add embiggen props to feed card so it can be showed in large feed view
  const embiggenedCards = (cards: Array<LiveFeedCardProps>) => {
    return cards.map((card) => ({ ...card, embiggen: true }));
  };

  //Exit from view mode
  const deactivateViewMode = () => {
    if (view.mode === ViewMode.LARGE) {
      dispatch(switchViewMode(ViewMode.NORMAL, TriggerPoint.FEED));
      dispatch(updateInViewSlideIndex(-1, TriggerPoint.FEED));
    }
  };

  //Show/hide live feed enlarged view
  const handleLiveFeedView = useCallback(() => {
    if (liveFeedViewRef.current) {
      if (view.mode === ViewMode.LARGE) {
        liveFeedViewRef.current.style.display = "block";
        document.body.style.overflow = "hidden";
        liveFeedViewRef.current.scrollTop = 0;
      } else {
        liveFeedViewRef.current.style.display = "none";
        document.body.style.overflow = "initial";
      }
    }
  }, [view, liveFeedViewRef]);

  useEffect(() => {
    dispatch(fetchFeedData());
  }, [dispatch]);

  useEffect(() => {
    if (feedFetcher.request) {
      setDisplayLoading(true);
    } else if (feedFetcher.fail) {
      setDisplayLoading(true);
    } else {
      setFeedPool(feedFetcher.data);
      setDisplayLoading(false);
    }
  }, [feedFetcher]);

  useEffect(() => {
    if (feedPool) setSearchResult(searchForFeed(feedFetcher.data, searchText));
  }, [searchText, feedPool, feedFetcher]);

  useEffect(() => {
    if (searchResult) setViewerCardPool(embiggenedCards(searchResult));
    setGoToSlide(view.currentIndex);
  }, [searchResult, view.currentIndex]);

  useEffect(() => {
    handleLiveFeedView();
  }, [handleLiveFeedView]);

  return (
    <div className="com-live-feed">
      <FadeIn>
        <div className="highlight-feed">
          <ul>
            {highlights.map((highlight) => {
              return (
                <li key={highlight.index}>
                  <div className="highlight-icon">
                    <a href={highlight.link} target="_blank" rel="noopener noreferrer">
                      <LazyImage src={highlight.image} alt="Feed highlight icon" />
                      <span>{highlight.title}</span>
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </FadeIn>
      <FadeIn>
        <div className="sns-filter-section">
          <div className="sns-icons">
            <a
              id="all"
              className="sns-filter-icon"
              onClick={(e) => {
                handleSNSFilter(e);
              }}
              href="#top"
            >
              <span style={{ color: snsIconColor.all }}>ALL POSTS</span>
            </a>
            {props.showDiscord && (
              <a
                id="discord"
                className="sns-filter-icon"
                onClick={(e) => {
                  handleSNSFilter(e);
                }}
                href="#top"
              >
                <FontAwesomeIcon icon={faDiscord} color={snsIconColor.discord} size="6x" />
              </a>
            )}
            {props.showFacebook && (
              <a
                id="facebook"
                className="sns-filter-icon"
                onClick={(e) => {
                  handleSNSFilter(e);
                }}
                href="#top"
              >
                <FontAwesomeIcon icon={faFacebook} color={snsIconColor.facebook} size="6x" />
              </a>
            )}
            {props.showInstagram && (
              <a
                id="instagram"
                className="sns-filter-icon"
                onClick={(e) => {
                  handleSNSFilter(e);
                }}
                href="#top"
              >
                <FontAwesomeIcon icon={faInstagram} color={snsIconColor.instagram} size="6x" />
              </a>
            )}
            {props.showYoutube && (
              <a
                id="youtube"
                className="sns-filter-icon"
                onClick={(e) => {
                  handleSNSFilter(e);
                }}
                href="#top"
              >
                <FontAwesomeIcon icon={faYoutube} color={snsIconColor.youtube} size="6x" />
              </a>
            )}
          </div>
          <div className="search-box">
            <input
              ref={searchRef}
              onChange={(e) => {
                handleSearch(e);
              }}
              type="text"
              placeholder="Search"
            />
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>
      </FadeIn>
      <FadeIn>
        <div className="feed-container">
          <LoadingSpin className={"loading-spin" + (displayLoading ? "" : " hide")} />
          <MasonryGrid cards={searchResult} />
        </div>
      </FadeIn>
      <div className="footer">
        {!displayLoading &&
          (location.pathname.indexOf("/community") === -1 ? (
            <NavLink className="more-feed" to={"/community"}>
              MORE FEED
            </NavLink>
          ) : (
            <button className="more-feed" onClick={handleMoreFeedBtn}>
              MORE FEED
            </button>
          ))}
      </div>
      <div ref={liveFeedViewRef} className="live-feed-viewer">
        <div className="background-layer" onClick={deactivateViewMode}></div>
        <ScrollMenu currentLocation={TriggerPoint.FEED} settings={embiggenSettings} goToIndex={goToSlide}>
          {viewerCardPool?.map((card, index) => {
            return <LiveFeedCard {...card} key={index} />;
          })}
        </ScrollMenu>
      </div>
    </div>
  );
};

export default LiveFeed;
