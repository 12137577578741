import * as React from "react";
import "./feed-section.scss";
import LiveFeed from "../../../livefeed/LiveFeed";
import FadeIn from "../../../fadein/FadeIn";
import { useLocalize } from "../../../../redux/actions/localizeAction";

export interface FeedDataType {
  title: string;
  description: string;
}

export interface FeedSectionProps {}

const FeedSection: React.FC<FeedSectionProps> = () => {
  const { data } = useLocalize<FeedDataType>("home.feed");

  return (
    <div className="com-feed-section">
      <FadeIn>
        <div className="com-feed-section__header">
          <p className="com-feed-section__title">{data?.title || ""}</p>
          <p className="com-feed-section__description">{data?.description || ""}</p>
        </div>
      </FadeIn>
      <LiveFeed showDiscord showFacebook showInstagram showYoutube />
    </div>
  );
};

export default FeedSection;
