import React, { useState, useEffect, useRef } from "react";
import "./LiveFeedCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { MediaType, SNS_LINKS, addLineBreak, TriggerPoint, PROFILE_PIC } from "../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { IViewState } from "../../../redux/reducers/viewReducer";
import { StoreState } from "../../../redux/reducers";
import { switchViewMode, updateSlideIndex, updateInViewSlideIndex, ViewMode } from "../../../redux/actions/viewAction";
import VideoPlayer from "../../videoplayer/VideoPlayer";
import LazyImage from "../../../libs/lazy-load/LazyImage";
import useIntersection from "../../../libs/utils/useIntersection";

export interface LiveFeedCardProps {
  id?: string;
  index?: number;
  videoUrl?: string;
  imageUrl?: string;
  embiggen?: boolean;
  text: string;
  link?: string;
  footer: {
    type: MediaType;
    profileName: string;
    date: string;
  };
}

const LiveFeedCard: React.FC<LiveFeedCardProps> = (props) => {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [videoID, setVideoID] = useState<string | undefined>(props.videoUrl);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandable, setExpandable] = useState<boolean>(false);
  const { inview } = useIntersection(descriptionRef, { once: true });
  let snsLink = "#top";
  const MAX_TEXT_HEIGHT = 152; //image height is 240, used to offset image height;
  const view = useSelector<StoreState, IViewState>((state) => state.liveFeedView);
  const dispatch = useDispatch();

  //Proccess sns profile pic and icon into feed card icon
  const generateMedia = () => {
    if (props.videoUrl !== "") {
      return (
        <div className="media-player">
          <VideoPlayer url={videoID} title="livefeed card" type={props.footer.type} thumbnail={props.imageUrl} />
        </div>
      );
    } else if (props.imageUrl !== "") {
      return <LazyImage className="media-image" src={props.imageUrl || ""} alt="" />;
    }
    return null;
  };

  //Determine the type of sns icon use for feed
  const generateIconSNS = () => {
    switch (props.footer.type) {
      case MediaType.Discord:
        snsLink = SNS_LINKS.discord;
        return (
          <a href={SNS_LINKS.discord} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} color="#7289d9" />
          </a>
        );
      case MediaType.Facebook:
        snsLink = SNS_LINKS.facebook;
        return (
          <a href={SNS_LINKS.facebook} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} color="#3b5998" />
          </a>
        );
      case MediaType.Instagram:
        snsLink = SNS_LINKS.instagram;
        return (
          <a href={SNS_LINKS.instagram} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} color="#405de6" />
          </a>
        );
      case MediaType.Youtube:
        snsLink = SNS_LINKS.youtube;
        return (
          <a href={SNS_LINKS.youtube} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} color="#ff0000" />
          </a>
        );
      default:
        throw new Error("Unable to find font for media type " + props.footer.type);
    }
  };

  //Handle expand/collapse
  const handleExpand = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  //Handle switching to large view mode
  const activateViewMode = () => {
    if (window.innerWidth < 768) {
      window.open(props.link);
    } else {
      if (view.mode !== ViewMode.LARGE && props.index !== undefined) {
        dispatch(updateSlideIndex(props.index, TriggerPoint.FEED));
        dispatch(switchViewMode(ViewMode.LARGE, TriggerPoint.FEED));
        dispatch(updateInViewSlideIndex(props.index, TriggerPoint.FEED));
        window.scrollTo(0, 0);
      } else if (view.mode === ViewMode.LARGE) {
        window.open(props.link);
      }
    }
  };

  //Add expand text
  useEffect(() => {
    if (descriptionRef.current && !props.embiggen && props.text.length > 0) {
      if (descriptionRef.current.scrollHeight > MAX_TEXT_HEIGHT) {
        setExpandable(true);
      }
    }
  }, [props.embiggen, props.text.length, inview]);

  //Load/reload the video when slide comes into view
  useEffect(() => {
    if (props.index === view.slideInViewIndex) {
      setVideoID(props.link);
    } else {
      setVideoID(undefined);
    }
  }, [props.link, props.index, view.slideInViewIndex]);

  return (
    <div className={`com-livefeed-card${props.embiggen ? " com-livefeed-card--large" : ""}`}>
      <div className={`com-livefeed-card__media${props.embiggen ? " com-livefeed-card__media--large" : ""}`} onClick={activateViewMode}>
        {generateMedia()}
      </div>
      <div className={`com-livefeed-card__text${props.embiggen ? " com-livefeed-card__text--large" : ""}`}>
        <div className="com-livefeed-card__enlarge-click-area" onClick={activateViewMode}></div>
        <div
          className={`com-livefeed-card__description
          ${expanded ? " com-livefeed-card--expanded" : ""}
          ${expandable ? " com-livefeed-card--expandable" : ""}
          ${props.embiggen ? " com-livefeed-card__description--large" : ""}`}
          ref={descriptionRef}
        >
          {addLineBreak(props.text)}
        </div>
        {expandable && (
          <a
            href="#top"
            className="com-livefeed-card__expand"
            onClick={(e) => {
              handleExpand(e);
            }}
          >
            {expanded ? "Collapse" : "Expand"}
          </a>
        )}
      </div>
      <div className="com-livefeed-card__footer">
        <div className="com-livefeed-card__profile">
          <LazyImage className="com-livefeed-card__profile-pic" src={PROFILE_PIC[props.footer.type]} />
          {generateIconSNS()}
        </div>
        <a href={snsLink} className="com-livefeed-card__profile-name" target="_blank" rel="noopener noreferrer">
          {props.footer.profileName}
        </a>
        <span>{props.footer.date}</span>
      </div>
      <div className="com-livefeed-card__footer-line"></div>
    </div>
  );
};

export default LiveFeedCard;
