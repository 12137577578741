import * as React from "react";
import "./CommunityFeed.scss";
import LiveFeed from "../../../livefeed/LiveFeed";
import FadeIn from "../../../fadein/FadeIn";

const CommunityFeed: React.FC = () => {
  return (
    <div className="com-community-feed">
      <FadeIn>
        <div className="com-community-feed__header">
          <p className="com-community-feed__title">STORIES</p>
        </div>
        <LiveFeed isPage showDiscord showFacebook showInstagram showYoutube />
      </FadeIn>
    </div>
  );
};

export default CommunityFeed;
