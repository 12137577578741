import * as React from "react";
import "./Banner.scss";
import StaticBanner, { StaticBannerDataType } from "../../../staticbanner/StaticBanner";
import { useLocalize } from "../../../../redux/actions/localizeAction";

export interface BannerProps {}

const Banner: React.FC<BannerProps> = () => {
  const { data } = useLocalize<StaticBannerDataType>("contentCreator.banner");

  return <div className="com-banner">{data && <StaticBanner data={data} classes={{ button: "inverse-button" }} />}</div>;
};

export default Banner;
