import * as React from "react";
import "./MasonryGrid.scss";
import LiveFeedCard, { LiveFeedCardProps } from "../livefeedcard/LiveFeedCard";
import { useEffect, useState } from "react";
import FadeIn from "../../fadein/FadeIn";
import { chunk } from "lodash-es";

export interface MasonryGridProps {
  cards: Array<LiveFeedCardProps>;
}

const MasonryGrid: React.FC<MasonryGridProps> = (props) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const BREAKPOINT_MOBILE = 601;
  const BREAKPOINT_TABLET = 1023;
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const generateColumns = () => {
    const tempLeft: Array<JSX.Element> = [];
    const tempMiddle: Array<JSX.Element> = [];
    const tempRight: Array<JSX.Element> = [];
    const chunkSize =
      windowWidth > BREAKPOINT_TABLET
        ? 3
        : windowWidth > BREAKPOINT_MOBILE
        ? 2
        : 1;

    chunk(props.cards, chunkSize).forEach((chunk, index) => {
      chunk.forEach((card, cardIndex) => {
        card.index = index * chunkSize + cardIndex;
      });
      tempLeft.push(
        <FadeIn key={index} fromBelow>
          <LiveFeedCard {...chunk[0]} />
        </FadeIn>
      );
      if (chunk[1])
        tempMiddle.push(
          <FadeIn key={index} fromBelow>
            <LiveFeedCard {...chunk[1]} />
          </FadeIn>
        );
      if (chunk[2])
        tempRight.push(
          <FadeIn key={index} fromBelow>
            <LiveFeedCard {...chunk[2]} />
          </FadeIn>
        );
    });

    return (
      <>
        <div className="com-masonry-grid__column">{tempLeft}</div>
        <div className="com-masonry-grid__column">{tempMiddle}</div>
        {tempRight.length > 0 && (
          <div className="com-masonry-grid__column">{tempRight}</div>
        )}
      </>
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.cards, windowWidth]);

  return <div className={"com-masonry-grid"}>{generateColumns()}</div>;
};

export default MasonryGrid;
