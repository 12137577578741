import { ResponsiveMedia, SystemActionType } from "../actions/systemAction";

export enum SystemAction {
  SET_SCREEN_SIZE = "SET_SCREEN_SIZE",
  SET_RESPONSIVE_SIZE = "SET_RESPONSIVE_SIZE",
}

// action state type define
export interface SystemActionState {
  screenSize: {
    width: number;
    height: number;
  };
  responsive: ResponsiveMedia | Record<string, boolean>;
}

// action initial state
const initialState: SystemActionState = {
  screenSize: { width: 0, height: 0 },
  responsive: {},
};

const systemReducer = (state: SystemActionState = initialState, action: SystemActionType): SystemActionState => {
  switch (action.type) {
    case SystemAction.SET_SCREEN_SIZE:
      return { ...state, screenSize: { ...action.payload } };
    case SystemAction.SET_RESPONSIVE_SIZE:
      return { ...state, responsive: { ...action.payload } };
    default:
      return state;
  }
};

export default systemReducer;
