import * as React from "react";
import "./InteractiveMap.scss";
import MapCard, { MapCardProps } from "./mapcard/MapCard";
import { PIN_LOCATION, TriggerPoint } from "../../utils";
import gsap from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { ReactComponent as LoadingSpin } from "../../assets/images/loading.svg";
import { IFetchState } from "../../redux/reducers/fetchReducer";
import { fetchData } from "../../redux/actions/fetchAction";
import FadeIn from "../fadein/FadeIn";
import Hammer from "hammerjs";
import LazyImage from "../../libs/lazy-load/LazyImage";

export interface InteractiveMapProps {}

const InteractiveMap: React.FC<InteractiveMapProps> = () => {
  const ZOOM_VALUE = 0.5;
  const mapRef = useRef<HTMLDivElement>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mobileMapRef = useRef<HTMLDivElement>(null);
  const mobileCardRef = useRef<HTMLDivElement>(null);
  const pinRef = useRef<HTMLSpanElement>(null);
  const [cardID, setCardId] = useState<string>("THE UFO INN");
  const [locationPool, setLocationPool] = useState<Array<MapCardProps>>();
  const [displayLoading, setDisplayLoading] = useState<boolean>(true);
  const [displayMap, setDisplayMap] = useState<boolean>(false);
  const [lastPosition, setLastPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [movePosition, setMovePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const dispatch = useDispatch();
  const fetchStatus = useSelector<StoreState, IFetchState>((state) => state.mapData);

  useEffect(() => {
    dispatch(fetchData(TriggerPoint.MAP));
  }, [dispatch]);

  useEffect(() => {
    if (fetchStatus.request) {
      setDisplayLoading(true);
      setDisplayMap(false);
    } else if (fetchStatus.fail) {
      setDisplayMap(false);
      setDisplayLoading(true);
    } else {
      setLocationPool(fetchStatus.data);
      setDisplayMap(true);
      setDisplayLoading(false);
    }
  }, [fetchStatus]);

  const handleOnClick = (event: React.MouseEvent<SVGPathElement | SVGGElement | HTMLDivElement, MouseEvent>, isMobile: boolean = false) => {
    setCardId(event.currentTarget.id);
    if (isMobile && mobileCardRef.current) {
      window.scrollTo(0, 0);
      gsap.to(mobileCardRef.current, 0.7, {
        delay: 0.5,
        x: 800,
        ease: "Power4.easeOut",
      });
    }
  };

  const handleBackButton = () => {
    if (mobileCardRef.current) {
      gsap.to(mobileCardRef.current, 0.5, { x: -800, ease: "Power1.easeIn" });
    }
  };

  const findCard = (cards: Array<MapCardProps>, id: string) => {
    return cards.find((child) => {
      return child.name === id;
    });
  };

  const generateCard = (isMobile: boolean = false) => {
    let displayCard;
    if (locationPool) {
      displayCard = findCard(locationPool, cardID);
    }
    if (displayCard) return <MapCard {...displayCard} isMobile={isMobile} />;
  };

  const generatePin = () => {
    let location = PIN_LOCATION[cardID];
    if (location) {
      return (
        <span ref={pinRef} className="pin" style={{ top: location[0] + "%", left: location[1] + "%" }}>
          <FontAwesomeIcon icon={faMapMarkerAlt} color="#FABF49" size="2x" />
        </span>
      );
    }
    return null;
  };

  const exploreCards = (() => {
    if (locationPool)
      return locationPool.map((card, index) => {
        return (
          <div
            key={index}
            className="explore-card"
            id={card.name}
            onClick={(e) => {
              handleOnClick(e, true);
            }}
          >
            <div className="card-content">
              <LazyImage className="card-image" src={card.image || ""} />
              <p className="card-name">{card.name}</p>
            </div>
          </div>
        );
      });
  })();

  const resizeHeight = useCallback(() => {
    let height;
    if (mobileCardRef.current && mobileMapRef.current) {
      height = mobileMapRef.current.clientHeight + "px";
      mobileCardRef.current.style.height = height;
    }
  }, [mobileCardRef, mobileMapRef]);

  useEffect(() => {
    resizeHeight();
    window.addEventListener("resize", resizeHeight);
  }, [resizeHeight]);

  useEffect(() => {
    if (pinRef.current) {
      gsap.to(pinRef.current, 0, {
        y: -30,
        opacity: 0,
      });
      gsap.to(pinRef.current, 0.5, {
        y: 0,
        opacity: 1,
      });
    }
  }, [pinRef]);

  const handleZoomIn = useCallback(() => {
    let newZoom = zoom + ZOOM_VALUE;
    if (mapRef.current && newZoom < 3) {
      setZoom(newZoom);
      mapRef.current.classList.add("zoom");
      mapRef.current.style.transform = "";
      setLastPosition({ x: 0, y: 0 });
      setMovePosition({ x: 0, y: 0 });
    }
  }, [zoom]);

  const handleZoomOut = useCallback(() => {
    let newZoom = zoom - ZOOM_VALUE;
    if (mapRef.current && newZoom >= 1) {
      setZoom(newZoom);
      mapRef.current.classList.add("zoom");
      mapRef.current.style.transform = "";
      setLastPosition({ x: 0, y: 0 });
      setMovePosition({ x: 0, y: 0 });
    }
  }, [zoom]);

  const zoomByMouse = useCallback(
    (e: React.WheelEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (e.deltaY < 0) handleZoomIn();
      else handleZoomOut();
    },
    [handleZoomIn, handleZoomOut]
  );

  const handlePan = useCallback(
    (e) => {
      const map = mapRef.current;
      const mapContainer = mapContainerRef.current;
      let paths = document.getElementsByTagName("path");
      if (map && mapContainer) {
        map.classList.remove("zoom");
        Array.from(paths).forEach((path) => {
          path.style.pointerEvents = "none";
        });
        if (e.deltaX !== 0 && e.deltaY !== 0) {
          let tempPosX = e.deltaX + lastPosition.x;
          let tempPosY = e.deltaY + lastPosition.y;
          let currentZoom = parseFloat(map.style.transform.split("(")[1].split(")")[0]);
          let limitX = Math.abs((mapContainer.clientWidth - map.clientWidth) * (currentZoom === 1 ? 0 : currentZoom) * 6);
          let limitY = Math.abs((mapContainer.clientWidth - map.clientWidth) * (currentZoom === 1 ? 0 : currentZoom) * 6);
          if (currentZoom !== 1) {
            if (Math.abs(tempPosX) < limitX) movePosition.x = tempPosX;
            if (Math.abs(tempPosY) < limitY) movePosition.y = tempPosY;
            map.style.transform = map.style.transform.split(" ")[0] + "translate(" + movePosition.x + "px , " + movePosition.y + "px)";
          }
        }
      }
    },
    [lastPosition, movePosition]
  );

  const handlePanEnd = useCallback(
    (e) => {
      let paths = document.getElementsByTagName("path");
      Array.from(paths).forEach((path) => {
        path.style.pointerEvents = "initial";
      });
      if (e.deltaX !== 0) lastPosition.x = movePosition.x;
      if (e.deltaY !== 0) lastPosition.y = movePosition.y;
    },
    [lastPosition, movePosition]
  );

  useEffect(() => {
    const map = mapRef.current;
    if (map) map.style.transform = "scale(" + zoom + ")";
  }, [zoom]);

  useEffect(() => {
    const map = mapRef.current;
    const mapContainer = mapContainerRef.current;
    if (map && mapContainer && window.innerWidth > 1024) {
      const hammer = new Hammer(mapContainer);
      hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL });
      hammer.on("panleft panright panup pandown press tap", (e) => handlePan(e));
      hammer.on("panend press tap", (e) => handlePanEnd(e));
    }
  }, [handlePan, handlePanEnd]);

  return (
    <div className="com-interactive-map">
      <FadeIn>
        <div className="header">
          <p className="title">MAP</p>
          <p className="description">CHECK THE LOCATIONS AND POPULAR SPOTS</p>
        </div>
      </FadeIn>
      <LoadingSpin className={"loading-spin " + (displayLoading ? "show" : "hide")} />
      <div className={"map-section " + (displayMap ? "show" : "hide")}>
        <div className="zoom-button">
          <div className="svg-background">
            <FontAwesomeIcon className="minus" icon={faMinusCircle} color="#ffffff" size="2x" onMouseDown={handleZoomOut} />
          </div>
          <div className="svg-background">
            <FontAwesomeIcon className="plus" icon={faPlusCircle} color="#ffffff" size="2x" onMouseDown={handleZoomIn} />
          </div>
        </div>
        <div ref={mapContainerRef} onWheel={(e) => zoomByMouse(e)} id="zoom-container" className="zoom-container">
          <div
            id="map"
            className="map zoom"
            style={{
              width: "370px",
              height: "370px",
            }}
            ref={mapRef}
          >
            <LazyImage src="images/map/mini_map.png" />
            <svg className="map-svg" width="818" height="857" viewBox="0 0 818 857" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="ROYAL FLUSH DAM"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M236 197.5C237.667 193.167 241 184.1 241 182.5L304 171L342.5 163.5L349.5 161.5V88.5L330 29.5L260 46L177 33.5H172L163.5 56L144.5 98L156 114C158.5 118.667 163.8 128.7 165 131.5C166.2 134.3 170.167 137.667 172 139L189.5 156.5L210 191L227.5 202L236 197.5Z"
              />
              <path
                id="PAYDIRT MINES"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M79 291L105 285L122 266L128 249.5L134.5 238L145.5 224L168 212.5L170.5 208C171.5 204.5 173.5 197.1 173.5 195.5C173.5 193.9 176.167 180.833 177.5 174.5V164.5L168 151L140 126L128 120.5L99.5 110.5L82.5 108.5L67.5 110.5L57.5 116.5L44.5 131C38.3333 135.833 25.8 145.8 25 147C24.2 148.2 8.66667 171.833 1 183.5L4.5 208L8.5 227.5L16.5 255.5L44.5 277.5L79 291Z"
              />
              <path
                id="SNAK EYES PARK"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M44 444.5L10 433L3.5 393.5L18 354V318H97C98.2 318 104.833 330.333 108 336.5C109.167 343.167 112 357.2 114 360C116 362.8 114.833 375.167 114 381V402.5L108 430.5L88.5 447.5L44 444.5Z"
              />
              <g
                id="CONSTRUCTION ZONE"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
              >
                <path d="M164 336V416.5H255.5V336H164Z" />
                <path d="M524.5 265.5C519.333 269 508.8 276.5 508 278.5L578.5 351L593.5 336.5L524.5 265.5Z" />
              </g>
              <g
                id="THE SHOPS"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
              >
                <path d="M347.5 420L364 415L367.5 341.5L357.5 326.5H349V312.5L164 313.5V335.5H255.5V415H337.5L347.5 420Z" />
                <path d="M491.5 503.5L477 510.5L400.5 437L429 409L491.5 475.5V503.5Z" />
              </g>
              <path
                id="DOWNTOWN"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M285.5 573.5C303.5 573.833 339.8 574.3 341 573.5V417.5L162.5 415.5V439L254.5 554L285.5 573.5Z"
              />
              <path
                id="AREA 52"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M279 669.5C266.833 675.5 242 687.8 240 689L204.5 685L140 665C125.833 643 97.8 598.7 99 597.5C100.2 596.3 84.8333 556.667 77 537L80.5 477L94.5 457.5L108 452.5L119.5 460.5L173.5 478.5L182.5 497.5L265 607L289.5 622.5L296.5 652.5L279 669.5Z"
              />
              <path
                id="INDUSTRY CITY"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M421 650H371C365.833 644.667 355.5 633.6 355.5 632C355.5 630.4 352.167 624.333 350.5 621.5V559H384L401.5 545.5L403.5 488L363.5 447.5L369 442C374.333 441.333 385.3 440 386.5 440C387.7 440 425 478.333 443.5 497.5V523.5H462L460 602.5H451.5L436 609.5L423 621.5L421 650Z"
              />
              <path
                id="CITY HALL"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M521 535.5L493 507.5V636.5H612V551H521V535.5Z"
              />
              <path
                id="THE VILLAS"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M611.5 641.5H479C479 644.667 477 652.3 469 657.5C459 664 449.5 679.758 449.5 698.5C449.5 716 468.167 730.333 479 737C486.667 739.333 502.4 744 504 744H532.5L568.5 729V716L593 698.5L604 678L611.5 641.5Z"
              />
              <path
                id="THE BUSINESS DISTRICT"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M379 407.5C385 416.833 397.3 435.3 398.5 434.5L428 404.5L494 473V501.5L527 534V543H618.5V483.5H566L428 349.5L387.5 346V376L379 407.5Z"
              />
              <path
                id="THE STRIP"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M388.5 265.5V343L427 348L565 480.5H618V393.5C578.667 355.167 498.9 277.3 494.5 272.5C490.1 267.7 484.667 265.833 482.5 265.5H388.5Z"
              />
              <path
                id="THE AIRPORT"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M660 324.5L620.5 338.5L500 217.5V179.5L532 156L567.5 151.5L660 198L719.5 254.5L721 268.5L710 290L684.5 302.5L660 324.5Z"
              />
              <path
                id="STADIUM"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M388.5 189V262H484.5L498 250L496.5 181.5H394.5L388.5 189Z"
              />
              <path
                id="THE UFO INN"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M375.5 162.5H355V88.5C363.833 80.5 381.8 64.2 383 63C384.2 61.8 458.167 24.1667 495 5.5L518 1.5L556 16.5H568.5L584 33V56L568.5 82.5L558.5 84L537 75.5L533 79L539.5 102.5L529.5 120.5L459.5 155L434.5 162.5L420.5 160L413.5 165L406.5 160L402 143.5L396 139L388.5 153.5L375.5 162.5Z"
              />
              <path
                id="LONELY ROAD"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M716.5 509.5L735.5 503.5L759 447L777.5 428.5L794 381V322.5L812.5 259L816.5 157L781.5 97C766.167 91.6667 730 79.2 708 72C686 64.8 666.5 72.3333 659.5 77C674 69.5 653.5 90.9 655.5 100.5C657.5 110.1 668.667 126.167 674 133L689 140.5L703 164.5L723 185.5L764 228L771.5 248L764 279L747.5 302.5L744 335L735.5 366L723 400L713 438.5L699 468.5L708 487V509.5H716.5Z"
              />
              <path
                id="RANCHO BANDITO"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M291.5 667L243 692L260.5 717L313 747C316.333 752 324.4 764.5 330 774.5C337 787 364 811.5 400 835.5C435.616 859.244 511.85 856.561 541.07 855.533L542 855.5C564.8 854.7 614.833 830.833 637 819C649.833 819.833 677.5 821.8 685.5 823C693.5 824.2 730.167 801.167 747.5 789.5C753.333 771.5 765.8 727.6 769 696C773 656.5 764 615 761.5 596C759.5 580.8 765.667 548.333 769 534L736.5 507.5L706.5 512.5V553.5C706.5 556.3 712.5 583 715.5 596L696.5 634.5L675.5 651L657 667C655.333 676.667 652.2 696.7 653 699.5C654 703 674 745.5 675.5 752C676.7 757.2 670 769.167 666.5 774.5L628 794L558.5 815.5H488.5C477.7 815.5 462.333 801.167 456 794C450.167 773.667 436.5 730.8 428.5 722C420.5 713.2 400.5 707.667 391.5 706C385.667 701.333 371.2 689.8 360 681C348.8 672.2 309.667 668 291.5 667Z"
              />
              <path
                id="RED LIGHT DISTRICT"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M164 274.5V309.5H366.5V234.5L342.5 211.5H293L271 234.5H215L164 274.5Z"
              />
            </svg>
          </div>
        </div>
        <div className="card-section">{generateCard()}</div>
      </div>
      <div className={"map-section-mobile " + (displayMap ? "show" : "hide")}>
        <div className="map-image">
          <div ref={mobileMapRef} className="map-background-mobile">
            <LazyImage className="map-background-image-mobile" src="images/map/mini_map.png" />
            {generatePin()}
            <svg className="map-svg" width="818" height="857" viewBox="0 0 818 857" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="ROYAL FLUSH DAM"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M236 197.5C237.667 193.167 241 184.1 241 182.5L304 171L342.5 163.5L349.5 161.5V88.5L330 29.5L260 46L177 33.5H172L163.5 56L144.5 98L156 114C158.5 118.667 163.8 128.7 165 131.5C166.2 134.3 170.167 137.667 172 139L189.5 156.5L210 191L227.5 202L236 197.5Z"
              />
              <path
                id="PAYDIRT MINES"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M79 291L105 285L122 266L128 249.5L134.5 238L145.5 224L168 212.5L170.5 208C171.5 204.5 173.5 197.1 173.5 195.5C173.5 193.9 176.167 180.833 177.5 174.5V164.5L168 151L140 126L128 120.5L99.5 110.5L82.5 108.5L67.5 110.5L57.5 116.5L44.5 131C38.3333 135.833 25.8 145.8 25 147C24.2 148.2 8.66667 171.833 1 183.5L4.5 208L8.5 227.5L16.5 255.5L44.5 277.5L79 291Z"
              />
              <path
                id="SNAK EYES PARK"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M44 444.5L10 433L3.5 393.5L18 354V318H97C98.2 318 104.833 330.333 108 336.5C109.167 343.167 112 357.2 114 360C116 362.8 114.833 375.167 114 381V402.5L108 430.5L88.5 447.5L44 444.5Z"
              />
              <g
                id="CONSTRUCTION ZONE"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
              >
                <path d="M164 336V416.5H255.5V336H164Z" />
                <path d="M524.5 265.5C519.333 269 508.8 276.5 508 278.5L578.5 351L593.5 336.5L524.5 265.5Z" />
              </g>
              <g
                id="THE SHOPS"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
              >
                <path d="M347.5 420L364 415L367.5 341.5L357.5 326.5H349V312.5L164 313.5V335.5H255.5V415H337.5L347.5 420Z" />
                <path d="M491.5 503.5L477 510.5L400.5 437L429 409L491.5 475.5V503.5Z" />
              </g>
              <path
                id="DOWNTOWN"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M285.5 573.5C303.5 573.833 339.8 574.3 341 573.5V417.5L162.5 415.5V439L254.5 554L285.5 573.5Z"
              />
              <path
                id="AREA 52"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M279 669.5C266.833 675.5 242 687.8 240 689L204.5 685L140 665C125.833 643 97.8 598.7 99 597.5C100.2 596.3 84.8333 556.667 77 537L80.5 477L94.5 457.5L108 452.5L119.5 460.5L173.5 478.5L182.5 497.5L265 607L289.5 622.5L296.5 652.5L279 669.5Z"
              />
              <path
                id="INDUSTRY CITY"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M421 650H371C365.833 644.667 355.5 633.6 355.5 632C355.5 630.4 352.167 624.333 350.5 621.5V559H384L401.5 545.5L403.5 488L363.5 447.5L369 442C374.333 441.333 385.3 440 386.5 440C387.7 440 425 478.333 443.5 497.5V523.5H462L460 602.5H451.5L436 609.5L423 621.5L421 650Z"
              />
              <path
                id="CITY HALL"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M521 535.5L493 507.5V636.5H612V551H521V535.5Z"
              />
              <path
                id="THE VILLAS"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M611.5 641.5H479C479 644.667 477 652.3 469 657.5C459 664 449.5 679.758 449.5 698.5C449.5 716 468.167 730.333 479 737C486.667 739.333 502.4 744 504 744H532.5L568.5 729V716L593 698.5L604 678L611.5 641.5Z"
              />
              <path
                id="THE BUSINESS DISTRICT"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M379 407.5C385 416.833 397.3 435.3 398.5 434.5L428 404.5L494 473V501.5L527 534V543H618.5V483.5H566L428 349.5L387.5 346V376L379 407.5Z"
              />
              <path
                id="THE STRIP"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M388.5 265.5V343L427 348L565 480.5H618V393.5C578.667 355.167 498.9 277.3 494.5 272.5C490.1 267.7 484.667 265.833 482.5 265.5H388.5Z"
              />
              <path
                id="THE AIRPORT"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M660 324.5L620.5 338.5L500 217.5V179.5L532 156L567.5 151.5L660 198L719.5 254.5L721 268.5L710 290L684.5 302.5L660 324.5Z"
              />
              <path
                id="STADIUM"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M388.5 189V262H484.5L498 250L496.5 181.5H394.5L388.5 189Z"
              />
              <path
                id="THE UFO INN"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M375.5 162.5H355V88.5C363.833 80.5 381.8 64.2 383 63C384.2 61.8 458.167 24.1667 495 5.5L518 1.5L556 16.5H568.5L584 33V56L568.5 82.5L558.5 84L537 75.5L533 79L539.5 102.5L529.5 120.5L459.5 155L434.5 162.5L420.5 160L413.5 165L406.5 160L402 143.5L396 139L388.5 153.5L375.5 162.5Z"
              />
              <path
                id="LONELY ROAD"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M716.5 509.5L735.5 503.5L759 447L777.5 428.5L794 381V322.5L812.5 259L816.5 157L781.5 97C766.167 91.6667 730 79.2 708 72C686 64.8 666.5 72.3333 659.5 77C674 69.5 653.5 90.9 655.5 100.5C657.5 110.1 668.667 126.167 674 133L689 140.5L703 164.5L723 185.5L764 228L771.5 248L764 279L747.5 302.5L744 335L735.5 366L723 400L713 438.5L699 468.5L708 487V509.5H716.5Z"
              />
              <path
                id="RANCHO BANDITO"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M291.5 667L243 692L260.5 717L313 747C316.333 752 324.4 764.5 330 774.5C337 787 364 811.5 400 835.5C435.616 859.244 511.85 856.561 541.07 855.533L542 855.5C564.8 854.7 614.833 830.833 637 819C649.833 819.833 677.5 821.8 685.5 823C693.5 824.2 730.167 801.167 747.5 789.5C753.333 771.5 765.8 727.6 769 696C773 656.5 764 615 761.5 596C759.5 580.8 765.667 548.333 769 534L736.5 507.5L706.5 512.5V553.5C706.5 556.3 712.5 583 715.5 596L696.5 634.5L675.5 651L657 667C655.333 676.667 652.2 696.7 653 699.5C654 703 674 745.5 675.5 752C676.7 757.2 670 769.167 666.5 774.5L628 794L558.5 815.5H488.5C477.7 815.5 462.333 801.167 456 794C450.167 773.667 436.5 730.8 428.5 722C420.5 713.2 400.5 707.667 391.5 706C385.667 701.333 371.2 689.8 360 681C348.8 672.2 309.667 668 291.5 667Z"
              />
              <path
                id="RED LIGHT DISTRICT"
                onClick={(e) => {
                  handleOnClick(e, true);
                }}
                d="M164 274.5V309.5H366.5V234.5L342.5 211.5H293L271 234.5H215L164 274.5Z"
              />
            </svg>
          </div>
        </div>
        <div ref={mobileCardRef} className="card-section-mobile">
          <div className="back-button" onClick={handleBackButton}>
            &lt; Back
          </div>
          {generateCard(true)}
        </div>
        <div className="explore">
          <div className="text-title">
            <p>EXPLORE LOCATIONS</p>
          </div>
          <div className="card-container">{exploreCards}</div>
        </div>
      </div>
    </div>
  );
};

export default InteractiveMap;
