import './ReCaptchaV3.scss';
import useScripts from "../script-loader";

const useReCaptcha3 = (sitekey: string) => {
  useScripts(
    () => { /*console.log('Recaptcha 3 Loaded')*/ },
    undefined,
    'https://www.google.com/recaptcha/api.js?render=' + sitekey
  )

  const execute = async (action: string) => {
    if(window.grecaptcha) {
      const token = await window.grecaptcha.execute(sitekey, { action });
      return token;
    }
    return null;
  }

  return execute;
}

const recaptcha3 = {
  execute: async (sitekey: string, action: string) => {
    if(window.grecaptcha) {
      const token = await window.grecaptcha.execute(sitekey, { action });
      return token;
    }
    return null;
  }
}

export {
  recaptcha3
}

export default useReCaptcha3;