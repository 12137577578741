import EActionTypes from "../actions/actionTypes";
import { TriggerPoint } from "../../utils";
import { FetchAction } from "../actions/fetchAction";

export interface IFetchState {
  request: boolean;
  fail: boolean;
  success: boolean;
  data: Array<any>;
  appendOffset: number;
}

const initialState: IFetchState = {
  request: false,
  fail: false,
  success: false,
  data: [],
  appendOffset: 0
};

export const createFetchReducer = (location: TriggerPoint) => {
  return (
    state: IFetchState = initialState,
    action: FetchAction
  ): IFetchState => {
    if (action.location !== location) return state;
    switch (action.type) {
      case EActionTypes.FETCH_REQUEST:
        return {
          ...state,
          request: true,
          fail: false,
          success: false
        };
      case EActionTypes.FETCH_SUCCESS:
        return {
          request: false,
          fail: false,
          success: true,
          data: action.payload,
          appendOffset: 5
        };
      case EActionTypes.FETCH_APPEND:
        return {
          request: false,
          fail: false,
          success: true,
          data: [...state.data, ...action.payload],
          appendOffset: state.appendOffset + 5
        };
      case EActionTypes.FETCH_FAILURE:
        return {
          ...state,
          request: false,
          fail: true,
          success: false
        };
      default:
        return state;
    }
  };
};
