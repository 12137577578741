import * as React from "react";
import "./Action.scss";
import ScrollMenu from "../scrollmenu/ScrollMenu";
import ActionCard, { ActionCardProps } from "./actioncard/ActionCard";
import { TriggerPoint } from "../../utils";
import FadeIn from "../fadein/FadeIn";
import LazyImage from "../../libs/lazy-load/LazyImage";
import { useLocalize } from "../../redux/actions/localizeAction";
import DOMPurify from "dompurify";
import { NavLink } from "react-router-dom";

export interface ActionDataType {
  headerTitleMobile: string;
  headerTitleDesktop: string;
  headerDescription: string;
  footerTitleMobile: string;
  footerTitleDesktop: string;
  footerDescription: string;
  buttonText: string;
  buttonUrl: string;
  cards: ActionCardProps[];
}

export interface ActionProps {}

const Action: React.FC<ActionProps> = () => {
  const sanitizer = DOMPurify.sanitize;

  const customBreak = {
    breakpoint: 674,
    settings: {
      centerPadding: "0px",
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  };

  const { data } = useLocalize<ActionDataType>("home.action");

  return (
    <div className="com-action">
      <div className="com-action__header">
        <FadeIn>
          <div
            className="com-action__title tablet"
            dangerouslySetInnerHTML={{
              __html: sanitizer(data?.headerTitleDesktop || ""),
            }}
          ></div>
          <div
            className="com-action__title mobile"
            dangerouslySetInnerHTML={{
              __html: sanitizer(data?.headerTitleMobile || ""),
            }}
          ></div>
          <p className="com-action__description">{data?.headerDescription || ""}</p>
        </FadeIn>
      </div>

      {data?.cards && (
        <FadeIn>
          <ScrollMenu currentLocation={TriggerPoint.NONE} dots={true} settings={{ initialSlide: 0 }} customBreaks={[customBreak]}>
            {data.cards.map((card, i) => {
              return <ActionCard {...card} key={i} />;
            })}
          </ScrollMenu>
        </FadeIn>
      )}

      <LazyImage className="com-action__left-soldier" src={"images/action/left-soldier.png"} />
      <LazyImage className="com-action__right-soldier" src={"images/action/right-soldier.png"} />

      <div className="com-action__shadow"></div>

      <div className="com-action__footer">
        <FadeIn>
          <div
            className="com-action__title desktop"
            dangerouslySetInnerHTML={{
              __html: sanitizer(data?.footerTitleDesktop || ""),
            }}
          ></div>
          <div
            className="com-action__title mobile"
            dangerouslySetInnerHTML={{
              __html: sanitizer(data?.footerTitleMobile || ""),
            }}
          ></div>
          <p className="com-action__description">{data?.footerDescription || ""}</p>
          <NavLink className="com-action__read-more" to={data?.buttonUrl || "/about"}>
            {data?.buttonText || "Read more"}
          </NavLink>
        </FadeIn>
      </div>
    </div>
  );
};

export default Action;
