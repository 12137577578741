export const CONTENT_CREATOR_QUERY = `{
    contentCreator{
      banner {
        title
        description
        buttonText
        buttonUrl
        background{url}
        character{url}
      }
      benefit{
        title
        description
        background{
          url         
        }
        cards{
          image{
            url
          }
          description
        }
      }
      bestVideo {
        title
        description
        background{
          url  
        }
        videos{
          type
          visualUrl{
            url  
          }
          logo{
            url  
          }
          name
          chanel
          infor
        }
      }
      hallGlory{
        title
        description
        listHall{
          logo{
            url  
          }
          name
          infor
        }
      }
      becomeCreator{
        title
        description
        background{
          url
        }
        cards{
          image{
            url
          }
          description
        }
      }
      reward{
        title
        description
        button
        btnLink
        background{
          url
        }
        beginner{
          url
        }
        young{
          url
        }
        real{
          url
        }
        legendary{
          url
        }
      }
      submit{
        title
        description
        background{
          url
        }
        ageAgreement
        creatorAgreement
        termAgreement
      }
    }
  }`;
