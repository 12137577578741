import * as React from "react";
import "./NewsCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import { triggerPopup, generateShareLinkTo, MediaType, clampText } from "../../../utils";
import dompurify from "dompurify";
import LazyImage from "../../../libs/lazy-load/LazyImage";

export interface NewsDataType {
  title: string;
  slug: string;
  description: string;
  content: string;
  date: string;
  image: SImage;
  hotspot: SImage;
  videoUrl: string;
  metaTitle: string;
  metaDescription: string;
  visible: boolean;
}

export interface NewsCardProps {
  data: NewsDataType;
}

const NewsCard: React.FC<NewsCardProps> = ({ data }) => {
  const sanitizer = dompurify.sanitize;
  // const cardClassName = data.isPage ? "com-news-card" : "com-news-card-hover";
  const facebookShareLink = generateShareLinkTo(MediaType.Facebook, "u=https://gangstar-vegas.com/" + data.slug);
  const twitterShareLink = generateShareLinkTo(MediaType.Twitter, `text=${data.title} https://gangstar-vegas.com/${data.slug}&original_referer=https://gangstar-vegas.com`);

  return (
    <div className="com-news-card">
      <div className="share-section">
        <p>Share it</p>
        <div className="sns-icons">
          <FontAwesomeIcon
            onClick={() => {
              triggerPopup(facebookShareLink);
            }}
            icon={faFacebook}
            color="#ffffff"
          />
          <FontAwesomeIcon
            onClick={() => {
              triggerPopup(twitterShareLink);
            }}
            icon={faTwitter}
            color="#ffffff"
          />
        </div>
      </div>
      <LazyImage src={data.image?.url || ""} alt="" className="card-image" />
      <div className="text-container">
        <div className="date">
          <p>{"By Gangstar | " + data.date}</p>
        </div>
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: sanitizer(clampText(data.title, 40), {
              ADD_TAGS: ["iframe"],
            }),
          }}
        ></div>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: sanitizer(clampText(data.description, 120), {
              ADD_TAGS: ["iframe"],
            }),
          }}
        ></div>
        <NavLink to={"/news/" + data.slug}>
          <button className="button-read-more">READ MORE</button>
        </NavLink>
      </div>
    </div>
  );
};

export default NewsCard;
