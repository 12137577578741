import classNames from "classnames";
import React, { useEffect, useState } from "react";

import styles from "./GLGPCPref.module.css";

export interface GLGPCPrefProps {
  children?: React.ReactNode;
  className?: string;
}

const GLGPCPref: React.FC<GLGPCPrefProps> = (props) => {
  const [glgpcAplly, setGLGPCAplly] = useState(false);
  const [defaultNoteText, setDefaultNoteText] = useState("");

  useEffect(() => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(function () {
      if ((navigator as any).glgpc !== undefined) setGLGPCAplly(true);
      setDefaultNoteText(window.GLGPC?.["locales"]?.["en"]?.["setting-note"] ?? "");
    });
  }, []);
  return glgpcAplly ? (
    <div className={classNames(styles["root"], props.className)} onClick={() => window.GLGPC?.showConsent()}>
      {props.children ? props.children : defaultNoteText}
    </div>
  ) : null;
};

export default GLGPCPref;
