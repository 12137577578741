import EActionTypes from "../actions/actionTypes";
import { ViewAction, ViewMode } from "../actions/viewAction";
import { TriggerPoint } from "../../utils";

export interface IViewState {
  mode: ViewMode;
  currentIndex?: number;
  slideInViewIndex?: number | null;
  id?: string | null;
}

const initialState: IViewState = {
  mode: ViewMode.NORMAL
};

export const createViewReducer = (location: TriggerPoint) => {
  return (state: IViewState = initialState, action: ViewAction): IViewState => {
    if (action.location !== location) return state;
    switch (action.type) {
      case EActionTypes.SWITCH_VIEW:
        return {
          ...state,
          mode: action.payload as ViewMode
        };
      case EActionTypes.UPDATE_SLIDE_INDEX:
        return { ...state, currentIndex: action.payload as number };
      case EActionTypes.UPDATE_IN_VIEW_SLIDE_INDEX:
        return { ...state, slideInViewIndex: action.payload as number };
      case EActionTypes.UPDATE_SLIDE_ID:
        return { ...state, id: action.payload as string };
      default:
        return state;
    }
  };
};
