import * as React from "react";
import "./CharacterPopup.scss";
import { addLineBreak } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CharacterCardProps } from "../charactercards/CharacterCard";
import { useState } from "react";

export interface CharacterPopupProps {
  isDisplayed: boolean;
  cardInView: CharacterCardProps;
}

const CharacterPopup: React.FC<CharacterPopupProps> = (props) => {
  const [displayed, setDisplayed] = useState<boolean>(props.isDisplayed);
  const card = props.cardInView;
  const handleXButton = () => {
    setDisplayed(false);
  };
  return (
    <div className={"com-character-popup" + (displayed ? "" : " hide")}>
      <FontAwesomeIcon icon={faTimes} className="x-button" color="#ffffff" size="3x" onClick={handleXButton} />
      <div className="detail-container">
        <div className="popup-header">
          <p className="short-name">{card.nick}</p>
          <p className="title">{card.title}</p>
        </div>
        <p className="full-name">{card.name}</p>
        <div className="skill-detail">
          <p className="skill">{"Skills: " + card.skill}</p>
          <p className="wanted-for">{card.wanted !== "" ? "Wanted for: " + card.wanted : ""}</p>
        </div>
        <div className="background">
          <p className="background-title">BACKGROUND</p>
          <br />
          <div className="background-description">{addLineBreak(card.background)}</div>
        </div>
        <div className="event">
          <p className="event-title">EVENTS OF GANGSTAR VEGAS</p>
          <br />
          <div className="event-description">{addLineBreak(card.event)}</div>
        </div>
        <br />
        <div className="trivia">
          <p className="trivia-title">TRIVIA</p>
          <div className="trivia-description">{addLineBreak(card.trivia)}</div>
        </div>
      </div>
    </div>
  );
};

export default CharacterPopup;
