import * as React from "react";
import "./Switch.scss";

export interface SwitchProps {
  onSwitch: () => void;
}

const Switch: React.FC<SwitchProps> = (props) => {
  return (
    <div className="com-switch">
      <label className="com-switch__button">
        <input
          type="checkbox"
          id="switch"
          className="com-switch__input"
          onClick={() => {
            props.onSwitch();
          }}
        />
        <span className="com-switch__slider"></span>
        <span className="com-switch__news">NEWS</span>
        <span className="com-switch__live">LIVE</span>
      </label>
    </div>
  );
};

export default Switch;
