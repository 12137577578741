import * as React from "react";
import "./SubmitVideo.scss";
import { useLocalize } from "../../../../redux/actions/localizeAction";
import LazyImage from "../../../../libs/lazy-load/LazyImage";
// import Dropdown from "./dropdown/Dropdown";
import { RECAPTCHA_SITEKEY, MediaDataType } from "../../../../utils";
import { recaptcha3 } from "../../../../utils/recaptcha/ReCaptchaV3";
import { useForm } from "react-hook-form";
import api, { SUB_MANAGER_URL } from "../../../../api";
import { ReactComponent as LoadingSpin } from "../../../../assets/images/loading.svg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export interface SubmitVideoDataType {
  title: string;
  description: string;
  background: MediaDataType;
  ageAgreement: string;
  creatorAgreement: string;
  termAgreement: string;
}

export interface VideoFormData {
  name: string;
  email: string;
  link: string;
  nickname: string;
  contact: string;
  playerID: string;
  submitted: boolean;
  captcha: string;
}

export interface SubmitVideoProps {}

const SubmitVideo: React.FC<SubmitVideoProps> = () => {
  const [popup, setPopup] = useState<{
    success?: boolean;
    show?: boolean;
    loading?: boolean;
  }>({
    success: false,
    show: false,
    loading: false,
  });
  const { data } = useLocalize<SubmitVideoDataType>("contentCreator.submit");
  const { handleSubmit, register } = useForm<VideoFormData>({});
  const onSubmit = async (submitData: VideoFormData, e: any) => {
    submitData.captcha = await recaptcha3.execute(RECAPTCHA_SITEKEY, "SubmitVideo");
    setPopup({ loading: true });

    const response = await api.post(SUB_MANAGER_URL + "/gangstar-vegas/subscribers", submitData);

    if (response && response.status === 200) {
      setPopup({ success: true, show: true, loading: false });
      e.target.reset();
    } else {
      setPopup({ success: false, show: true, loading: false });
    }
  };

  return (
    <div className="com-submit-video" id="submit">
      <LazyImage src={data?.background.url || ""} alt="" className="com-submit-video__background desktop" />
      <div className="com-submit-video__title">{data?.title || ""}</div>
      <div className="com-submit-video__description">{data?.description || ""}</div>
      <form className="com-submit-video__form" onSubmit={handleSubmit(onSubmit)}>
        <input name="name" type="text" className="com-submit-video__text-input" placeholder="Your Name" ref={register} />
        <input name="email" type="email" className="com-submit-video__text-input" placeholder="Email *" required pattern="^[\w._%+-]+@[\w.-]+.[\w]{2,4}$" ref={register} />
        <input name="link" type="url" className="com-submit-video__text-input" placeholder="Link To Video *" required ref={register} />
        <input name="nickname" type="text" className="com-submit-video__text-input" placeholder="Character name *" required maxLength={12} ref={register} />
        <input name="contact" type="text" className="com-submit-video__text-input" placeholder="Other Ways To Contact You" ref={register} />
        <div className="com-submit-video__tooltip-container">
          <input name="playerID" type="text" className="com-submit-video__text-input" placeholder="Player ID *" required ref={register} />
          <FontAwesomeIcon className="com-submit-video__help" icon={faInfoCircle} />
          <div className="com-submit-video__tooltip">You can find this information in the About section of the game</div>
        </div>

        <div className="com-submit-video__level">
          <select
            id="level"
            placeholder="Select View Level *"
            name="level"
            required
            className="com-submit-video__level-select"
            // classes={{
            //   root: "com-submit-video__reason",
            //   arrow: "com-submit-video__arrow",
            //   input: "com-submit-video__text-input",
            //   list: "com-submit-video__list",
            // }}
            ref={register}
          >
            {/* <div className="com-submit-video__header">
            <span>SELECT VIEWS LEVEL</span>
            <a href="#reward">View Levels</a>
          </div> */}
            <option className="com-submit-video__item" value="beginner">
              Beginner G (2,000 Views)
            </option>
            <option className="com-submit-video__item" value="young">
              Young G (10,000 Views)
            </option>
            <option className="com-submit-video__item" value="real">
              Real G (50,000 Views)
            </option>
            <option className="com-submit-video__item" value="legendary">
              Legendary G (200,000 Views)
            </option>
            <option className="com-submit-video__item" value="other">
              Other
            </option>
          </select>
        </div>
        <div className="com-submit-video__radio-container">
          <span className="com-submit-video__sub-title">HAVE YOU SUBMITTED THIS VIDEO BEFORE?</span>
          <label htmlFor="yes" className="com-submit-video__label">
            <input id="yes" type="radio" name="submitted" value="true" className="com-submit-video__radio" required ref={register} />
            Yes
          </label>
          <label htmlFor="no" className="com-submit-video__label">
            <input id="no" type="radio" name="submitted" value="false" className="com-submit-video__radio" required ref={register} />
            No
          </label>
        </div>
        <label className="com-submit-video__checkbox">
          <input type="checkbox" name="confirmedAge" required ref={register} />
          <span dangerouslySetInnerHTML={{ __html: data?.ageAgreement || "" }}></span>
        </label>
        <label className="com-submit-video__checkbox">
          <input type="checkbox" name="agreedGVCR" required ref={register} />
          <span dangerouslySetInnerHTML={{ __html: data?.creatorAgreement || "" }}></span>
        </label>
        <label className="com-submit-video__checkbox">
          <input type="checkbox" name="agreedTouPp" required ref={register} />
          <span dangerouslySetInnerHTML={{ __html: data?.termAgreement || "" }}></span>
        </label>
        <div className="com-submit-video__recaptcha">
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
        {popup.loading ? (
          <LoadingSpin className="com-submit-video__loading" />
        ) : (
          <button className="com-submit-video__submit inverse-button seo-content-creator-submit-video-tracking-btn" type="submit" value="">
            SUBMIT VIDEO
          </button>
        )}
      </form>
      {popup.show && (
        <div className="com-submit-video__popup">
          <FontAwesomeIcon className="com-submit-video__x-button" icon={faTimes} onClick={() => setPopup({ show: false })} />
          {popup.success ? (
            <div className="com-submit-video__popup-content">
              <p className="com-submit-video__popup-title">Success!</p>
              <p className="com-submit-video__popup-text">Thank you for the submission!</p>
              <p className="com-submit-video__popup-text">We'll review the video & will get back to you shortly!</p>
            </div>
          ) : (
            <div className="com-submit-video__popup-content">
              <p className="com-submit-video__popup-title">Error!</p>
              <p className="com-submit-video__popup-text">Something went wrong!</p>
              <p className="com-submit-video__popup-text">Please try again later.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubmitVideo;
