import * as React from "react";
import "./ContentCreator.scss";
import Banner from "./banner/Banner";
import Header from "../../header/Header";
import Benefit from "./benefit/Benefit";
import { useLocalize } from "../../../redux/actions/localizeAction";
import BecomeCreator from "./become-creator/BecomeCreator";
import Footer from "../../footer/Footer";
import Reward from "./reward/Reward";
import SubmitVideo from "./submit-video/SubmitVideo";
import { Helmet } from "react-helmet";
import { CONTENT_CREATOR_QUERY } from "../../../query/content-creator";

export interface ContentCreatorProps {}

const ContentCreator: React.FC<ContentCreatorProps> = () => {
  useLocalize("contentCreator", { method: "POST", postData: { query: CONTENT_CREATOR_QUERY }, api: "/graphql", selector: "data.contentCreator" });

  return (
    <div className="com-content-creator">
      <Helmet>
        <title>Gangstar Vegas | Join Us and Become an Influencer</title>
        <meta name="description" content="Become a content creator for Gangstar Vegas - online with 3 EASY STEPS! Get flexible rewards system and early access to upcoming updates! Join us today!" />
      </Helmet>
      <Header></Header>
      <Banner></Banner>
      <Benefit></Benefit>
      <BecomeCreator></BecomeCreator>
      <Reward></Reward>
      {/* <div className="wrapper-bestcase">
        <BestVideo></BestVideo>
        <HallGlory></HallGlory>
      </div> */}
      <SubmitVideo></SubmitVideo>
      <Footer></Footer>
    </div>
  );
};

export default ContentCreator;
