import * as React from "react";
import "./CharacterCard.scss";
import { useDispatch } from "react-redux";
import { updateSlideIndex, updateInViewSlideIndex } from "../../../redux/actions/viewAction";
import { TriggerPoint } from "../../../utils";
import LazyImage from "../../../libs/lazy-load/LazyImage";

export interface CharacterCardProps {
  id: number;
  image: string;
  nick: string;
  name: string;
  title: string;
  skill: string;
  weapon: string;
  wanted: string;
  quote: string;
  description: string;
  background: string;
  event: string;
  trivia: string;
  images: Array<string>;
  isOverlay?: boolean;
}

const CharacterCard: React.FC<CharacterCardProps> = (props) => {
  const dispatch = useDispatch();
  const handleClickEvent = () => {
    if (!props.isOverlay) {
      dispatch(updateSlideIndex(props.id, TriggerPoint.CHARACTERS));
      dispatch(updateInViewSlideIndex(props.id, TriggerPoint.CHARACTERS));
    }
  };

  return (
    <div className={"com-character-card" + (props.isOverlay ? "-overlay" : "")} onClick={handleClickEvent}>
      <div className="inner-card">
        <div className="flip-card-front">
          <LazyImage className="character-image" src={props.image} alt={props.name + " image"} />
          <div className="text-container">
            <p className="nick-name">{props.nick}</p>
            <p className="full-name">{props.name}</p>
            <p className="title">{props.title}</p>
            <p className="description">{props.description}</p>
          </div>
          <div className="button-read-more">{`READ MORE >>`}</div>
        </div>
        <div className="flip-card-back">
          <LazyImage
            className="character-image"
            src={props.image}
            alt={props.name + " image"}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CharacterCard;
