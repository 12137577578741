import React, { useEffect, useState } from "react";

export interface DidomiPrefProps {
  children: React.ReactNode;
  className?: string;
}

const DidomiPref: React.FC<DidomiPrefProps> = (props) => {
  const [gdprAplly, setGdprAplly] = useState(false);

  useEffect(() => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(function (Didomi: any) {
      if (Didomi.isRegulationApplied("gdpr")) setGdprAplly(true);
    });
  }, []);
  return gdprAplly ? (
    <div className={props.className} onClick={() => window.Didomi?.preferences.show()}>
      {props.children}
    </div>
  ) : null;
};

export default DidomiPref;
