import * as React from "react";
import "./ActionCard.scss";
import LazyImage from "../../../libs/lazy-load/LazyImage";

export interface ActionCardProps {
  image: SImage;
  title: string;
  description: string;
}

const ActionCard: React.FC<ActionCardProps> = (props) => {
  return (
    <div className="com-action-card">
      <LazyImage src={props.image?.url} alt="New level of open world" className="card-image" />
      <div className="text-container">
        <div className="title">
          <p>{props.title}</p>
        </div>
        <div className="description">
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ActionCard;
