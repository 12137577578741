import * as React from "react";
import "./Signup.scss";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { COUNTRIES, RECAPTCHA_SITEKEY } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as LoadingSpin } from "../../assets/images/loading.svg";
import FadeIn from "../fadein/FadeIn";
import { recaptcha3 } from "../../utils/recaptcha/ReCaptchaV3";
import CustomSelect from "./custom-select/CustomSelect";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import api, { SUB_MANAGER_URL } from "../../api";
import { useLocalize } from "../../redux/actions/localizeAction";
import { isArray } from "lodash-es";

export interface SignupProps {}

export interface SignupFormData {
  firstName: string;
  lastName: string;
  email: string;
  birthdate: string;
  platform: string;
  country: string;
  promo_gameloft: number | boolean;
  promo_gangstar: number | boolean;
  agree: string;
  captcha: string;
  [key: string]: any;
}

export interface SignupDataType {
  title: string;
  description: string;
  name: string;
  nameError: string;
  lastname: string;
  lastnameError: string;
  email: string;
  emailError: string;
  birthdate: string;
  birthdateError: string;
  platform: string[];
  newsletterAgreement: string;
  newsletterGameloft: string;
  term: string;
  termError: string;
  buttonText: string;
}

const Signup: React.FC<SignupProps> = () => {
  const { data } = useLocalize<SignupDataType>("home.newsletter");

  const [popup, setPopup] = useState<{
    loading?: boolean;
    success?: boolean;
    show?: boolean;
  }>({ loading: false, success: false, show: false });
  const { handleSubmit, register, reset, control, errors } = useForm<SignupFormData>();

  const maxDate = new Date(new Date().getFullYear() - 17, 1, 1);

  const closePopup = () => {
    setPopup({ show: false });
  };

  const onSubmit = async (data: SignupFormData) => {
    data.captcha = await recaptcha3.execute(RECAPTCHA_SITEKEY, "SignUpForm");
    data.promo_gameloft = Number(data.promo_gameloft);
    data.promo_gangstar = Number(data.promo_gangstar);
    let date = new Date(data.birthday);
    data.birthday = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    setPopup({ loading: true });

    const response = await api.post(SUB_MANAGER_URL + "/gangstar-vegas-newsletter/subscribers", data);

    if (response.status === 200) {
      setPopup({ loading: false, show: true, success: true });
      reset();
    } else {
      setPopup({ loading: false, show: true, success: false });
    }
  };

  return (
    <div className="com-signup">
      <div className="com-signup__container">
        {popup.show && (
          <div className="com-signup__popup">
            <FontAwesomeIcon className="com-signup__x-button" icon={faTimes} onClick={closePopup} />
            {popup.success ? (
              <>
                <p className="com-signup__popup-title">Success!</p>
                <div className="com-signup__popup-content">
                  <p>Thanks for subscribing.</p>
                  <p>Fresh news from Gangstar Vegas is on the way!</p>
                </div>
              </>
            ) : (
              <>
                <p className="com-signup__popup-title">Error!</p>
                <div className="com-signup__popup-content">
                  <p>Something went wrong!</p>
                  <p>Please try again later.</p>
                </div>
              </>
            )}
          </div>
        )}
        <FadeIn>
          <div className="com-signup__title">{data?.title || ""}</div>
          <div className="com-signup__description">{data?.description || ""}</div>
        </FadeIn>
        <FadeIn>
          <form onSubmit={handleSubmit(onSubmit)} className="com-signup__form" action="Request" method="Post" name="signup" autoComplete="off" role="presentation">
            <div className="com-signup__input-wrapper">
              <input className="com-signup__input" name="firstName" type="text" placeholder={data?.name || "FIRST NAME"} ref={register({ required: true })} />
              <p className={`com-signup__error${errors.firstName ? " opa-1" : " opa-0"}`}>{data?.nameError || "PLEASE ENTER YOUR FIRST NAME"}</p>
            </div>
            <div className="com-signup__input-wrapper">
              <input className="com-signup__input" name="lastName" type="text" placeholder={data?.lastname || "LAST NAME"} ref={register({ required: true })} />
              <p className={`com-signup__error${errors.lastName ? " opa-1" : " opa-0"}`}>{data?.lastnameError || "PLEASE ENTER YOUR LAST NAME"}</p>
            </div>
            <div className="com-signup__input-wrapper">
              <input
                className="com-signup__input"
                name="email"
                type="email"
                placeholder={data?.email || "EMAIL"}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: "",
                  },
                })}
              />
              <p className={`com-signup__error${errors.email ? " opa-1" : " opa-0"}`}>{data?.emailError || "PLEASE ENTER YOUR EMAIL"}</p>
            </div>
            <div className="com-signup__input-wrapper">
              <div className="com-signup__date-picker">
                <Controller
                  control={control}
                  name="birthdate"
                  rules={{ required: true }}
                  render={(props) => (
                    <DatePicker
                      selected={props.value}
                      onChange={(e) => props.onChange(e)}
                      maxDate={maxDate}
                      placeholderText={data?.birthdate || "BIRTHDATE"}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onBlur={(e) => {
                        if (window.innerWidth < 768) {
                          e.currentTarget.readOnly = false;
                        }
                      }}
                      onFocus={(e) => {
                        if (window.innerWidth < 768) {
                          e.currentTarget.readOnly = true;
                        }
                      }}
                    />
                  )}
                />
                <FontAwesomeIcon className="arrow-down" icon={faChevronDown} />
              </div>
              <p className={`com-signup__error${errors.birthday ? " opa-1" : " opa-0"}`}>{data?.birthdateError || "PLEASE ENTER YOUR DATE OF BIRTH"}</p>
            </div>
            <CustomSelect label="Platform" name="platform" inputRef={register({ required: true })} classes={{ root: "com-signup--mobile-mb" }}>
              {(isArray(data?.platform) && data?.platform ? data?.platform : ["IOS", "ANDROID", "WINDOWS", "OTHER"]).map((platform) => (
                <option key={platform} value={platform} className="com-signup__item-select">
                  {platform}
                </option>
              ))}
            </CustomSelect>
            <CustomSelect label="country" name="country" inputRef={register({ required: true })} classes={{ root: "com-signup--mobile-mb" }}>
              {COUNTRIES.map((country) => {
                return (
                  <option key={country.value} value={country.value} className="com-signup__item-select">
                    {country.name}
                  </option>
                );
              })}
            </CustomSelect>
            {data?.newsletterAgreement && data?.newsletterAgreement !== "" && (
              <label className="com-signup__agreement pt-m">
                <input type="checkbox" name="promo_gangstar" className="com-signup__agreement-input" ref={register} />
                {data?.newsletterAgreement}
              </label>
            )}
            {data?.newsletterGameloft && data?.newsletterGameloft !== "" && (
              <label className="com-signup__agreement">
                <input type="checkbox" name="promo_gameloft" className="com-signup__agreement-input" ref={register} />
                {data?.newsletterGameloft}
              </label>
            )}
            <div className="com-signup__agreement-wrapper">
              <label className="com-signup__agreement">
                <input type="checkbox" name="agree" className="com-signup__agreement-input" ref={register({ required: true })} />
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.term ||
                        'I agree to the Gameloft <a href="https://www.gameloft.com/en/conditions-of-use" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and I have read the <a href="https://www.gameloft.com/en/privacy-notice" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.',
                    }}
                  ></p>
                  <p className={`com-signup__error${errors.agree ? " opa-1" : " opa-0"}`}>{data?.termError || "YOU MUST AGREE TO THE TERMS AND CONDITIONS AND READ THE PRIVACY POLICY."}</p>
                </div>
              </label>
            </div>
            <div className="com-signup__recaptcha">
              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
            {popup.loading ? (
              <LoadingSpin className="com-signup__loading" />
            ) : (
              <button className="com-signup__submit seo-sign-up-tracking-btn" type="submit" value="">
                {data?.buttonText || "SIGN UP"}
              </button>
            )}
          </form>
        </FadeIn>
      </div>
      <div className="com-signup__shadow-overlay"></div>
    </div>
  );
};

export default Signup;
