import * as React from "react";
import "./MoreGames.scss";
import { useState, useEffect } from "react";
import { TriggerPoint } from "../../utils";
import { ReactComponent as LoadingSpin } from "../../assets/images/loading.svg";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { IFetchState } from "../../redux/reducers/fetchReducer";
import { fetchData } from "../../redux/actions/fetchAction";
import LazyImage from "../../libs/lazy-load/LazyImage";

export interface GameCardProps {
  icon: string;
  title: string;
  genre: string;
  description: string;
  link: string;
  rating: string;
}

const GameCard: React.FC<GameCardProps> = (props) => {
  const generateStars = (rating: string) => {
    let rate = parseFloat(rating),
      percentage;
    if (rate > 5) rate = 5;
    percentage = (rate / 5) * 100;

    return (
      <div
        className="rating-stars"
        style={{
          background: "linear-gradient(90deg, #FABF49 " + percentage + "%, #ffffff " + (100 - percentage) + "%)",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        ★★★★★
      </div>
    );
  };
  return (
    <div className="com-game-card">
      <a href={props.link} className="link-to-game" target="_blank" rel="noopener noreferrer">
        <div className="brief-container">
          <LazyImage src={props.icon} alt="" className="game-icon" />
          <div className="text-container">
            <p className="title">{props.title}</p>
            <div className="rating">
              {generateStars(props.rating)}
              <span className="rating-number">{props.rating}</span>
            </div>
            <p className="genre">{props.genre}</p>
          </div>
        </div>
        <p className="description">{props.description}</p>
      </a>
    </div>
  );
};

export interface MoreGamesProps {}

const MoreGames: React.FC<MoreGamesProps> = () => {
  const [gamePool, setGamePool] = useState<Array<GameCardProps>>();
  const [displayLoading, setDisplayLoading] = useState<boolean>(true);
  const gameFetcher = useSelector<StoreState, IFetchState>((state) => state.gamesData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData(TriggerPoint.GAMES));
  }, [dispatch]);

  useEffect(() => {
    if (gameFetcher.request) {
      setDisplayLoading(true);
    } else if (gameFetcher.fail) {
      setDisplayLoading(true);
    } else {
      setDisplayLoading(false);
      setGamePool(gameFetcher.data);
    }
  }, [gameFetcher]);

  const generateGameCards = () => {
    if (gamePool) {
      return gamePool.map((card, index) => {
        return <GameCard key={index} {...card} />;
      });
    }
    return null;
  };

  return (
    <div className="com-more-games">
      <LazyImage className="background" src="images/community/more_game_bg.png" />
      <div className="container">
        <div className="header">
          <p>YOU MAY ALSO LIKE</p>
        </div>
        <LoadingSpin className={"loading-spin" + (displayLoading ? "" : " hide")} />
        <div className="game-list">{generateGameCards()}</div>
      </div>
    </div>
  );
};

export default MoreGames;
