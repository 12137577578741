import { ThunkAction } from "redux-thunk";
import { LocalizeAction } from "../reducers/localizeReducer";
import { useDispatch, useStore, useSelector } from "react-redux";
import get from "lodash-es/get";
import cloneDeep from "lodash-es/cloneDeep";
import { useCallback, useState } from "react";
import { flatten, isArray, remove, unset } from "lodash-es";
import axios, { CancelTokenSource } from "axios";
import { ViewsData } from "../../mock-data/mock-all";
import api from "../../api";
import { StoreType, dispatch, IBaseAction } from "..";

// action type define

export interface LocalizeAction_SetData extends IBaseAction {
  type: LocalizeAction.SET_DATA;
  payload: {
    storePath: string;
    lang?: string; // default will be active lang
    data: any;
  };
}

export interface LocalizeAction_ChangeLanguage extends IBaseAction {
  type: LocalizeAction.CHANGE_LANGUAGE;
  payload: {
    language: string;
  };
}

export interface LocalizeAction_Clear extends IBaseAction {
  type: LocalizeAction.CLEAR;
  payload: {
    storePath: string;
  };
}

export type LocalizeActionType = LocalizeAction_SetData | LocalizeAction_ChangeLanguage | LocalizeAction_Clear; // | any other action;

// action creator

export const localizeAction_changeLanguage = (language: string): LocalizeAction_ChangeLanguage => {
  return {
    type: LocalizeAction.CHANGE_LANGUAGE,
    payload: {
      language,
    },
  };
};

export const localizeAction_setData = (storePath: string, lang: string, options: LocalizeOptionsType): ThunkAction<void, StoreType, undefined, LocalizeActionType> => async (dispatch) => {
  const apiUrl = typeof options === "string" ? options : options.api;
  const key = cacheKey(storePath, !!apiUrl);
  const cache = __cache[key];
  let data = await getLocalizeData(options);
  // update cache status when end
  cache.status = data ? "done" : "error";

  const selector = typeof options === "object" ? options.selector : undefined;
  if (data && selector) data = get(data, selector);
  if (typeof options === "object" && options.resolvedData) data = options.resolvedData(cloneDeep(data));

  dispatch({
    type: LocalizeAction.SET_DATA,
    payload: {
      storePath,
      lang,
      data,
    },
  });
};

export const localizeAction_Clear = (storePath: string): LocalizeAction_Clear => {
  return {
    type: LocalizeAction.CLEAR,
    payload: {
      storePath,
    },
  };
};

//////////////////////////// helper api ///////////////////////////////

export const getLocalizeData = async (options: LocalizeOptionsType) => {
  const useGet: boolean = typeof options === "string" || options.method !== "POST";
  const apiPath = typeof options === "string" ? options : options.api;
  const response = useGet ? await api.get(apiPath) : await api.post(apiPath, { ...(options as LocalizeOptions).postData });

  if (!response.data || response.data.error !== undefined) {
    // mock data FIXME: remove in production
    const mockData = (ViewsData as any)[(options as LocalizeOptions).mockPath || apiPath];
    return mockData;
  }

  return response.data;
};

//////////////////////////// helper hooks //////////////////////////////
type LocalizeReturnStatus = "idle" | "fetching" | "error" | "done";
type LocalizeOptions<T = any> = {
  api: string;
  method?: "GET" | "POST";
  postData?: {};
  mockPath?: string;
  selector?: string;
  enable?: boolean;
  maxRetry?: number;
  resolvedData?: (data: any) => T;
};
type LocalizeOptionsType<T = any> = LocalizeOptions<T> | string;
type LocalizeReturnType<T> = {
  data: T | undefined;
  status: LocalizeReturnStatus;
};

export const useLanguage = (language?: string) => {
  const store = useStore<StoreType>().getState();
  const dispatch = useDispatch();
  if (!language) {
    const saveLang = localStorage.getItem("language");
    language = saveLang ? saveLang : "en";
  }

  if (language && store.localize.language !== language) {
    // save to local
    localStorage.setItem("language", language);
    // dispatch change
    setTimeout(() => dispatch(localizeAction_changeLanguage(language!)), 0);
  }
};

export const useLocalize = <T = any>(storePath: string, options: LocalizeOptionsType<T> = ""): LocalizeReturnType<T> => {
  const absPath = storePath.startsWith(".");
  const path = absPath ? storePath.substr(1) : storePath;
  const apiUrl = typeof options === "string" ? options : options.api;
  const key = cacheKey(storePath, !!apiUrl);
  const maxRetry = typeof options === "string" ? 3 : options.maxRetry || 3; // default retry times: 3
  const enable = typeof options === "string" ? true : options.enable !== undefined ? options.enable : true;

  const cache = __cache[key] || {};

  // select lang from store so whenever lang change it will notify this to check again
  const lang = useSelector<StoreType, string>((store) => store.localize.language);
  // init cache
  if (
    apiUrl && // cache init for request with api only
    (!__cache[key] || // cache not exist
      (cache.status === "done" && cache.api && cache.api !== apiUrl) || // done but api change
      (cache.status === "error" && cache.api && cache.api !== apiUrl) || // error but api change
      (cache.status === "fetching" && cache.api && cache.api !== apiUrl)) // fetching but api change
  ) {
    if (cache.status === "fetching") cache.cancelToken.cancel("localize cancel");
    cache.status = "idle";
    cache.api = apiUrl;
    cache.retry = 0;
    cache.cancelToken = axios.CancelToken.source();
    __cache[key] = cache;
  }

  // select from store
  const resultData: T = useSelector<StoreType, any>((store) => {
    if (cache?.status === "error") return { __error: Date.now() }; // return obj to force comp re-render
    return !apiUrl || cache?.status === "done" ? (absPath ? get(store.localize.data, path) : get(store.localize.active, path)) : undefined;
  });

  const dispatch = useDispatch();

  if (apiUrl && enable && lang)
    setTimeout(() => {
      if (
        (!resultData || (resultData as any)?.__error) && // there have no data
        (cache.status === "idle" || // idle state
          (cache.status === "error" && cache.retry < maxRetry - 1)) // retry not exceed
      ) {
        if (cache.status === "error") cache.retry += 1;
        cache.status = "fetching";
        cache.requestOptions = options;
        dispatch(localizeAction_setData(storePath, lang, options));
      }
    }, 0);

  return {
    data: !apiUrl || cache.status === "done" ? resultData : undefined,
    status: cache.status,
  };
};

type LocalizePageReturnType<T> = {
  latestPage: T[] | undefined;
  allPages: [T[]] | undefined;
  data: T[] | undefined;
  fetchNextPage: () => void;
  fetchPrevPage: () => void;
  fetchPage: (page: number) => void;
  reset: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  status: LocalizeReturnStatus;
};
type LocalizePageOptions<T = any> = LocalizeOptions<T[]> & {
  getPageParam: (page: number) => string;
  checkNextPage?: (data: any) => boolean;
  checkPrevPage?: (data: any) => boolean;
};
type LocalizePageConfig = {
  page: number;
  pageSize: number;
  rowCount: number;
  pageCount: number;
};

const _defaultPageCheckNext = (data: any) => {
  const config: LocalizePageConfig = data?.page;
  if (config) {
    return config.page < config.pageCount;
  } else if (isArray(data)) return data.length !== 0;
  return true;
};

const _defaultPageCheckPrev = (data: any) => {
  const config: LocalizePageConfig = data?.page;
  if (config) {
    return config.page > 1;
  } else if (isArray(data)) return data.length !== 0;
  return true;
};

// data in store
type LocPageStore<T> = {
  latest: T[];
  all: [T[]];
};
export const useLocalizePage = <T = any>(storePath: string, options: LocalizePageOptions<T>): LocalizePageReturnType<T> => {
  const [page, setPage] = useState(0); // current page
  const [hasNext, setHasNext] = useState(true);
  const [hasPrev, setHasPrev] = useState(false);
  const [fetchAction, setFetchAction] = useState<"next" | "prev" | "set">("set");

  const fetchNextPage = useCallback(() => {
    if (hasNext) {
      setPage((p) => p + 1);
      setFetchAction("next");
      setHasPrev(true);
    }
  }, [hasNext]);

  const fetchPrevPage = useCallback(() => {
    if (hasPrev) {
      setPage((p) => {
        const prev = p - 1 >= 0 ? p - 1 : 0;
        if (prev === 0) setHasPrev(false);
        return prev;
      });
      setFetchAction("prev");
      setHasNext(true);
    }
  }, [hasPrev]);

  const fetchPage = useCallback((page: number) => {
    setPage(page);
    setFetchAction("set");
    setHasNext(true);
    setHasPrev(true);
  }, []);

  const reset = useCallback(() => {
    setPage(0);
    setFetchAction("set");
    setHasNext(true);
    setHasPrev(true);
    dispatch({
      type: LocalizeAction.SET_DATA,
      payload: {
        storePath,
        data: {
          latest: [],
          all: [],
        },
      },
    });
  }, [storePath]);

  const { data: latestPage } = useLocalize(`${storePath}.latest`);
  const { data: allPages } = useLocalize<[T[]]>(`${storePath}.all`);

  const param = options.getPageParam(page);
  const api = options.api.indexOf("?") > 0 ? `${options.api}&${param}` : `${options.api}${param === "" ? "" : `?${param}`}`;
  const pagePath = `${storePath}.all[${page}]`;

  const resolvedData = (rawdata: any) => {
    // check next/prev available
    if (fetchAction === "next") {
      const canNext = (options.checkNextPage || _defaultPageCheckNext)(rawdata);
      !canNext && setHasNext(false);
    }
    if (fetchAction === "prev") {
      const canPrev = (options.checkPrevPage || _defaultPageCheckPrev)(rawdata);
      !canPrev && setHasPrev(false);
    }

    const transData: T[] = options.resolvedData?.(rawdata) || rawdata;
    const all: [T[]] = [...(allPages || [[]])];
    all[page] = transData;

    const storeData: LocPageStore<T> = {
      latest: transData,
      all,
    };

    // update page store
    dispatch({
      type: LocalizeAction.SET_DATA,
      payload: {
        storePath,
        data: storeData,
      },
    });
    return transData;
  };

  const { status } = useLocalize(pagePath, { ...options, api, resolvedData });

  return { latestPage, allPages, data: remove(flatten(allPages), (v) => v !== undefined), fetchNextPage, fetchPrevPage, fetchPage, reset, status, hasNext, hasPrev };
};

export const localizeRefresh = (storePath: string) => {
  const key = cacheKey(storePath, true);
  if (__cache[key]) {
    // only refresh the request with api
    setTimeout(() => dispatch(localizeAction_setData(storePath, "", __cache[key].requestOptions) as any), 0);
  }
};

export const localizeClear = (storePath: string) => {
  setTimeout(() => {
    // also clear cache with key
    const key = keyStore(storePath);
    for (let k in __cache) {
      if (k.startsWith(key)) unset(__cache, k);
    }
    dispatch(localizeAction_Clear(storePath));
  }, 0);
};

export const localizeSetData = (storePath: string, data: any) => {
  setTimeout(() => {
    dispatch({
      type: LocalizeAction.SET_DATA,
      payload: {
        storePath,
        data,
      },
    });
  }, 0);
};

// utils functions

// use store path as key for request with api, other child path will get the status from parent
// get the cache for a request
const cacheKey = (store: string, absolute: boolean) => {
  const key = keyStore(store);
  if (absolute || __cache[key]) return key;
  // loop to find the parent
  for (let k in __cache) {
    if (key.startsWith(k)) return k;
  }
  return key;
};
// the the cache key for given store
const keyStore = (store: string) => {
  const language = localStorage.getItem("language") || "en";
  return store.startsWith(".") ? store : `${language}#${store}`;
};

type CacheObject = {
  status: LocalizeReturnStatus;
  retry: number; // retry time
  api: string; // api that data successful/failed fetch
  requestOptions: LocalizeOptionsType | LocalizePageOptions; // keep for refresh
  cancelToken: CancelTokenSource;
};
const __cache: Record<string, CacheObject> = {};
