import * as React from "react";
import "./Community.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import MoreGames from "../../moregames/MoreGames";
import TopContainer from "./top-container/TopContainer";
import { useSystem } from "../../../redux/actions/systemAction";

export interface CommunityProps {}

const Community: React.FC<CommunityProps> = () => {
  useSystem();

  return (
    <div className="com-community">
      <Header />
      <TopContainer />
      <MoreGames />
      <Footer />
    </div>
  );
};

export default Community;
