import * as React from "react";
import "./ScrollMenu.scss";
import { default as Slider, CustomArrowProps, Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as NextArrowImage } from "../../assets/images/action/arrow-point-to-right.svg";
import { ReactComponent as PreviousArrowImage } from "../../assets/images/action/arrow-point-to-left.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateInViewSlideIndex } from "../../redux/actions/viewAction";
import { gsap } from "gsap";
import { TriggerPoint } from "../../utils";

export interface ScrollMenuProps {
  id?: string;
  dots?: boolean;
  slidesForDesktop?: number;
  slidesForTablet?: number;
  slidesForPhone?: number;
  customBreaks?: Array<{
    breakpoint: number;
    settings: Settings;
  }>;
  settings?: Settings;
  currentLocation: TriggerPoint;
  goToIndex?: number;
  asNavFor?: React.RefObject<Slider>;
  useAnimation?: boolean;
}

const applyAnimation = (name: string, time: number, delay: number, fromX: number, toX: number, ease: string) => {
  const params = {
    name: name,
    time: time,
    delay: delay,
    fromX: fromX,
    toX: toX,
    ease: ease,
  };
  setTimeout(() => {
    let element = document.getElementsByClassName("slick-slide slick-active slick-current")[0]?.getElementsByClassName("com-character-card-overlay")[0]?.getElementsByClassName(params.name)[0];
    if (element) {
      gsap.to(element, 0, {
        x: params.fromX,
        display: "none",
      });
      gsap.to(element, params.time, {
        x: params.toX,
        delay: params.delay,
        display: "block",
        ease: params.ease,
      });
    }
  }, 1);
};

const NextArrow: React.FC<CustomArrowProps> = (props) => {
  return (
    <div className={props.className} style={{ ...props.style, display: "block" }} onClick={props.onClick}>
      <NextArrowImage />
    </div>
  );
};

const PreviousArrow: React.FC<CustomArrowProps> = (props) => {
  return (
    <div className={props.className} style={{ ...props.style, display: "block" }} onClick={props.onClick}>
      <PreviousArrowImage />
    </div>
  );
};

const ScrollMenu = React.forwardRef<any, React.PropsWithChildren<ScrollMenuProps>>((props, ref) => {
  const scrollMenuRef = React.createRef<HTMLDivElement>();
  const [slider, setSlider] = useState<React.RefObject<Slider>>();
  const [asNavFor, setAsNavFor] = useState<Slider>();
  const [goToIndex, setGoToIndex] = useState<number>();
  const dispatch = useDispatch();
  const settings = {
    dots: props.dots,
    customPaging: () => {
      return (
        <a href="#section">
          <div className="dots"></div>
        </a>
      );
    },
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    centerMode: false,
    centerPadding: "0px",
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    beforeChange: (oldIndex: number, newIndex: number) => {
      //if (props.currentLocation !== TriggerPoint.CHARACTERS)
      dispatch(updateInViewSlideIndex(newIndex, props.currentLocation));
      let fromX = 400;
      if (oldIndex > newIndex) {
        fromX = -400;
      }

      // setTimeout(() => {
      //   let card = document
      //     .getElementsByClassName("slick-slide slick-active slick-current")[0]
      //     ?.getElementsByClassName("com-character-card-overlay")[0];
      //   if (card) {
      //     gsap.to(card, 0, {
      //       opacity: 0
      //     });
      //     gsap.to(card, 0.5, {
      //       opacity: 1,
      //       delay: 0.5
      //     });
      //   }
      // }, 1);

      applyAnimation("character-image", 0.1, 0.1, fromX, 0, "Power0.easeNone");
      applyAnimation("nick-name", 0.5, 0.3, fromX, 0, "Expo.easeOut");
      applyAnimation("full-name", 0.5, 0.4, fromX, 0, "Expo.easeOut");
      applyAnimation("title", 0.5, 0.5, fromX, 0, "Expo.easeOut");
      applyAnimation("description", 0.5, 0.6, fromX, 0, "Expo.easeOut");
    },
    afterChange: (currentIndex: number) => {
      dispatch(updateInViewSlideIndex(currentIndex, props.currentLocation));
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: props.slidesForDesktop ? props.slidesForDesktop : 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          slidesToShow: props.slidesForTablet ? props.slidesForTablet : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "-30px",
          arrows: false,
          slidesToShow: props.slidesForPhone ? props.slidesForPhone : 1.5,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      ...(props.customBreaks || []),
    ],
    ...props.settings,
  };
  const useAnimation = props.useAnimation ? props.useAnimation : false;

  useEffect(() => {
    setSlider(ref !== undefined && ref !== null ? (ref as React.MutableRefObject<Slider>) : React.createRef<Slider>());
  }, [ref]);

  useEffect(() => {
    if (slider) {
      let oldIndex = goToIndex === undefined ? 0 : goToIndex;
      let newIndex = props.goToIndex === undefined ? 0 : props.goToIndex;
      let lastIndex = React.Children.count(props.children) - 1;
      if (slider.current && props.goToIndex !== undefined) {
        setGoToIndex(props.goToIndex);
        if (newIndex === 0 && oldIndex === lastIndex && props.currentLocation === TriggerPoint.CHARACTERS) {
          slider.current.slickNext();
        } else if (oldIndex === 0 && newIndex === lastIndex && props.currentLocation === TriggerPoint.CHARACTERS) {
          slider.current.slickPrev();
        } else if (newIndex !== goToIndex) {
          slider.current.slickGoTo(newIndex, !useAnimation);
        }
      }
    }
  }, [slider, props.goToIndex, useAnimation, goToIndex, props.children, props.currentLocation]);

  useEffect(() => {
    if (props.asNavFor && props.asNavFor.current) {
      setAsNavFor(props.asNavFor.current);
    }
  }, [props.asNavFor, slider]);

  return (
    <div id={props.id} ref={scrollMenuRef} className="com-scroll-menu">
      <Slider ref={slider} {...settings} asNavFor={asNavFor}>
        {React.Children.toArray(props.children).map((child) => {
          return child;
        })}
      </Slider>
    </div>
  );
});

export default ScrollMenu;
