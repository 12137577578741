import { useEffect } from "react";

type Callback = (script: string) => void;

const loadedScripts: string[] = [];

const useScripts = (success?: Function, failed?: Callback, ...scripts: (string | string[])[]) => {
  useEffect(() => {
    let loadIdx = 0;
    const next = (_: string) => {
      loadIdx++;
      if (loadIdx === scripts.length) {
        if (success) success();
      } else {
        load(scripts[loadIdx]);
      }
    };

    const stop = (script: string) => {
      if (failed) failed(script);
    };

    const load = (script: string | string[]) => {
      if (Array.isArray(script)) {
        parallel(script, next, stop);
      } else {
        loadScript(script, next, stop);
      }
    };

    load(scripts[0]);
  }, [scripts]); // eslint-disable-line
};

// interface ScriptOptions {
//   async: boolean;
//   defer: boolean;
// }

const loadScript = (scriptUrl: string, success: Callback, failed: Callback) => {
  if (loadedScripts.indexOf(scriptUrl) >= 0) {
    success(scriptUrl);
    return;
  }

  let script = document.createElement("script");
  script.src = scriptUrl;
  // load options
  script.async = true;

  const onSuccess = () => {
    loadedScripts.push(scriptUrl);
    success(scriptUrl);
    removeListener();
  };

  const onError = () => {
    failed(scriptUrl);
    removeListener();
  };

  const removeListener = () => {
    script.removeEventListener("load", onSuccess);
    script.removeEventListener("error", onError);
  };

  script.addEventListener("load", onSuccess);
  script.addEventListener("error", onError);
  document.body.appendChild(script);
};

const parallel = (scripts: string[], success: Callback, failed: Callback) => {
  let nLoaded = 0;
  const onSuccess = (script: string) => {
    nLoaded++;
    if (nLoaded === scripts.length) {
      // all scripts loaded
      success("");
    }
  };

  const onError = (script: string) => {
    failed(script);
  };

  scripts.forEach((script) => {
    loadScript(script, onSuccess, onError);
  });
};

export default useScripts;
