import { useEffect, useState } from "react";

type UseIntersectionProps = {
  once?: boolean;
};
type UseIntersectionType = IntersectionObserverInit & UseIntersectionProps;

const useIntersection = (ref: React.RefObject<HTMLElement> | string | null, options?: UseIntersectionType) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const { once, root, rootMargin, threshold } = options || {};

  useEffect(() => {
    const node = typeof ref == "string" ? document.querySelector(ref) : ref?.current;
    if (node && window.IntersectionObserver) {
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        setEntry(entries[0]);
        once && entries[0].isIntersecting && observer.disconnect();
      };

      const observer = new window.IntersectionObserver(handleIntersection, {
        root: root,
        rootMargin: rootMargin,
        threshold: threshold,
      });
      observer.observe(node);

      return () => observer.disconnect();
    }
  }, [ref, once, root, rootMargin, threshold]);

  return { inview: entry?.isIntersecting ? true : false, entry };
};

export default useIntersection;
