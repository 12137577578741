import * as React from "react";
import "./LegalNotice.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

export interface LegalNoticeProps {}

const LegalNotice: React.FC<LegalNoticeProps> = () => {
  return (
    <div className="com-legal">
      <Header />
      <div className="legal-content">
        <div className="header">
          <div className="title">LEGAL NOTICES</div>
        </div>
        <div className="content">
          <ul>
            <li>Company name: Gameloft S.E.</li>
            <li>Business address: 14 rue Auber, 75009, Paris, France</li>
            <li>Phone Number and email: +33 (0)1 53 16 20 40 / support@gameloft.com</li>
            <li>Legal Form of the Company: European Company</li>
            <li>Amount of the Capital: 4 421 551.10 Euros; Registration number in the Trade Register: 429 338 130 R.C.S. Paris</li>
            <li>Tax Payer Identification Number: EU VAT FR96439338130</li>
            <li>Name of the director of publication: Stéphane Roussel, President.</li>
            <li>Website host name, address and contact: Gameloft Divertissement Inc, 7250 rue Marconi, Montreal H2R 2Z5, Quebec, Canada</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LegalNotice;
