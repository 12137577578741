import * as React from "react";
import "./CharacterSlide.scss";
import ScrollMenu from "../scrollmenu/ScrollMenu";
import CharacterCard, { CharacterCardProps } from "./charactercards/CharacterCard";
import { TriggerPoint } from "../../utils";
import Slider, { Settings } from "react-slick";
import { useEffect, createRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../redux/reducers";
import { IViewState } from "../../redux/reducers/viewReducer";
import FadeIn from "../fadein/FadeIn";
import { IFetchState } from "../../redux/reducers/fetchReducer";
import { fetchData } from "../../redux/actions/fetchAction";
import { ReactComponent as LoadingSpin } from "../../assets/images/loading.svg";
import CharacterPopup from "./characterpopup/CharacterPopup";

export interface CharacterSlideProps {}

const CharacterSlide: React.FC<CharacterSlideProps> = () => {
  const view = useSelector<StoreState, IViewState>((state) => state.characterView);
  const overlaySlideRef = createRef<Slider>();
  const [goToSlide, setGoToSlide] = useState<number>();
  // const [displayPopup, setDisplayPopup] = useState<boolean>(false);
  const [popupCard, setPopupCard] = useState<CharacterCardProps>({
    id: 0,
    image: "",
    nick: "",
    name: "",
    title: "",
    weapon: "",
    wanted: "",
    skill: "",
    description: "",
    quote: "",
    background: "",
    event: "",
    trivia: "",
    images: [],
  });
  const overlaySlideSettings: Settings = {
    slidesToShow: 1,
    arrows: false,
    dots: false,
    initialSlide: 0,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    speed: 500,
    fade: true,
    swipe: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
        },
      },
    ],
  };
  const mainSlideSetting: Settings = {
    infinite: true,
    initialSlide: 0,
    centerMode: true,
    centerPadding: "250px",
    slidesToShow: 3,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "-30px",
          arrows: true,
        },
      },
    ],
  };

  const dispatch = useDispatch();
  const characterFetcher = useSelector<StoreState, IFetchState>((state) => state.characterData);
  const [characterPool, setCharacterPool] = useState<Array<CharacterCardProps>>();
  const [displayLoading, setDisplayLoading] = useState<boolean>(true);

  useEffect(() => {
    setGoToSlide(view.currentIndex || 0);
    //safeguard against multiple click
    setGoToSlide(view.slideInViewIndex || 0);
  }, [view.currentIndex, view.slideInViewIndex]);

  useEffect(() => {
    if (view.currentIndex && characterFetcher.data) setPopupCard(characterFetcher.data[view.currentIndex]);
  }, [view.currentIndex, view.slideInViewIndex, characterFetcher.data]);

  useEffect(() => {
    dispatch(fetchData(TriggerPoint.CHARACTERS));
  }, [dispatch]);

  useEffect(() => {
    if (characterFetcher.request) {
      setDisplayLoading(true);
    } else if (characterFetcher.fail) {
      setDisplayLoading(true);
    } else {
      setCharacterPool(characterFetcher.data);
      setDisplayLoading(false);
    }
  }, [characterFetcher, overlaySlideRef]);

  const generateCards = (isOverlay: boolean = false) => {
    if (characterPool) {
      return characterPool.map((card, index) => {
        return <CharacterCard {...card} id={index} key={index} isOverlay={isOverlay} />;
      });
    }
    return null;
  };

  return (
    <div className="com-character-slide">
      <FadeIn>
        <div className="header">
          <div className="title-container">
            <p className="title">Characters</p>
          </div>
          <p className="description">Meet all characters in the Gangstar series</p>
        </div>
      </FadeIn>
      <CharacterPopup isDisplayed={false} cardInView={popupCard} />
      <FadeIn>
        <LoadingSpin className={"loading-spin" + (displayLoading ? "" : " hide")} />
      </FadeIn>
      <div className={"overlay-card" + (displayLoading ? " hide" : "")}>
        <ScrollMenu ref={overlaySlideRef} currentLocation={TriggerPoint.CHARACTERS} slidesForDesktop={1} slidesForPhone={1} slidesForTablet={1} settings={overlaySlideSettings} goToIndex={goToSlide || 0} useAnimation>
          {generateCards(true)}
        </ScrollMenu>
      </div>
      <div className={"main-slide" + (displayLoading ? " hide" : "")}>
        <ScrollMenu currentLocation={TriggerPoint.CHARACTERS} dots asNavFor={overlaySlideRef} settings={mainSlideSetting} goToIndex={goToSlide || 0} useAnimation>
          {generateCards()}
        </ScrollMenu>
      </div>
    </div>
  );
};

export default CharacterSlide;
