import * as React from "react";
import "./CityMap.scss";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import InteractiveMap from "../../interactivemap/InteractiveMap";

export interface MapProps {}

const CityMap: React.FC<MapProps> = () => {
  return (
    <div className="com-city-map">
      <Header />
      <InteractiveMap />
      <Footer />
    </div>
  );
};

export default CityMap;
