import React, { useEffect, useState, useCallback, useRef } from "react";
import "./Video.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faVolumeUp, faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { useLocalize } from "../../redux/actions/localizeAction";

export interface VideoDataType {
  videoUrl: string;
  storeLeftIcon: SImage;
  storeLeftUrl: string;
  storeRightIcon: SImage;
  storeRightUrl: string;
}

export interface VideoProps {}

const Video: React.FC<VideoProps> = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playBtn = useRef<HTMLDivElement>(null);
  const [isMute, setMute] = useState<boolean>(true);

  const { data } = useLocalize<VideoDataType>("home.video");

  const handleSoundControl = useCallback(() => {
    setMute((m) => !m);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    setTimeout(() => {
      if (video) {
        if (window.innerWidth < 768 || localStorage.getItem("ageGate") !== "pass") {
          video.pause();
        } else {
          video.play();
        }
      }
    }, 1);
  }, []);

  return (
    <div className="com-video">
      <div className="sound-controller">
        <FontAwesomeIcon onClick={handleSoundControl} className={"sound-on" + (isMute ? " hidden" : "")} icon={faVolumeUp}></FontAwesomeIcon>
        <FontAwesomeIcon onClick={handleSoundControl} className={"sound-off" + (isMute ? "" : " hidden")} icon={faVolumeMute}></FontAwesomeIcon>
      </div>
      <div className="video-container">
        <div ref={playBtn} className="play-button">
          <FontAwesomeIcon
            className="play-button"
            onClick={() => {
              if (videoRef.current && playBtn.current) {
                videoRef.current.play();
                playBtn.current.remove();
              }
            }}
            icon={faPlay}
          />
        </div>

        {data && (
          <video ref={videoRef} className="top-video" loop muted={isMute} autoPlay>
            <source src={data.videoUrl} type="video/mp4" />
          </video>
        )}
      </div>
      <div className="tryit">
        <div className="container">
          <p>TRY IT NOW</p>
          <div className="store-icon">
            <a href={data?.storeLeftUrl} target="_blank" rel="noopener noreferrer">
              <img src={data?.storeLeftIcon?.url} alt="" />
            </a>
            <a href={data?.storeRightUrl} target="_blank" rel="noopener noreferrer">
              <img src={data?.storeRightIcon?.url} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
