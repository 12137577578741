import * as React from "react";
import "./StaticBanner.scss";
import FadeIn from "../fadein/FadeIn";
import LazyImage from "../../libs/lazy-load/LazyImage";
import { join } from "lodash-es";

export interface StaticBannerDataType {
  title: string;
  description: string;
  buttonText: string;
  buttonUrl: string;
  background: SImage;
  character: SImage;
}

export interface StaticBannerProps {
  data?: StaticBannerDataType;
  classes?: {
    button?: string;
  };
}

const StaticBanner: React.FC<StaticBannerProps> = ({ classes, data }) => {
  return (
    <div className="com-static-banner">
      <LazyImage src={data?.background?.url || ""} className="com-static-banner__background" />
      <LazyImage src={data?.character?.url || ""} alt="" className="com-static-banner__character" />
      <div className="com-static-banner__gradient"></div>

      <div className="com-static-banner__content">
        <FadeIn>
          <div className="com-static-banner__title">{data?.title}</div>

          <div className="com-static-banner__description">{data?.description}</div>
          <a className={join(["com-static-banner__learn-more seo-content-creator-submit-video-tracking-btn", classes?.button], " ")} href={data?.buttonUrl || ""}>
            {data?.buttonText}
          </a>
        </FadeIn>
      </div>
    </div>
  );
};

export default StaticBanner;
