import * as React from "react";
import "./Synopsis.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import LazyImage from "../../../libs/lazy-load/LazyImage";

export interface SynopsisProps {}

const Synopsis: React.FC<SynopsisProps> = () => {
  return (
    <div className="com-synopsis-page">
      <Header />
      <div className="container">
        <div className="story-container">
          <p className="title">THE STORY</p>
          <div className="text-content">
            <p>Rising mixed martial arts champion Jason Malone was supposed to take a dive in his fight for the world title.</p>
            <br />
            <p>But when his opponent beats him to the punch and goes down first, casino owner and Vegas kingpin Frank Veliano’s perfect plan goes down too. Now Jason is the most wanted man in Vegas. With no options left, he teams up with Veliano’s greatest rival to defeat Frank's dirty crew.</p>
            <br />
            <p>But in the City of Sin, things are not always what they seem, and your enemy today could very well become your boss tomorrow… Welcome to Vegas, where fortunes are made and lives are lost with a roll of the dice.</p>
          </div>
        </div>
        <LazyImage className="image" src="images/synopsis/group_soldiers.png" />
      </div>
      <Footer />
    </div>
  );
};

export default Synopsis;
