import { StaticBannerDataType } from "../components/staticbanner/StaticBanner";
import { BenefitDataType } from "../components/views/contentcreator/benefit/Benefit";
import { BecomeCreatorDataType } from "../components/views/contentcreator/become-creator/BecomeCreator";
import { RewardDataType } from "../components/views/contentcreator/reward/Reward";

//Hall Glory
import logoHall from "../assets/images/creator/best-video/icon_chanel.jpg";
import { HallsGloryDataType } from "../components/views/contentcreator/hall-glory/HallGlory";

//Bestvideo
import bgVideo from "../assets/images/creator/best-video/bg-video.jpg";
import { BestVideosDataType } from "../components/views/contentcreator/best-video/BestVideo";
import { SubmitVideoDataType } from "../components/views/contentcreator/submit-video/SubmitVideo";

// type define
type MockType = {
  banner: StaticBannerDataType;
  benefit: BenefitDataType;
  bestVideos: BestVideosDataType;
  hallGlory: HallsGloryDataType;
  becomeCreator: BecomeCreatorDataType;
  reward: RewardDataType;
  submit: SubmitVideoDataType;
};

export const Content_Creator_Mock_Data: MockType = {
  banner: {
    title: "CONTENT CREATOR PROGRAM",
    description: "BECOME AN INFLUENCER & CONTENT CREATOR WITH GANGSTAR VEGAS!",
    buttonText: "SUBMIT VIDEO",
    buttonUrl: "#submit",
    character: { url: "images/content-creator/banner/Jason.jpg" },
    background: { url: "images/content-creator/banner/background.jpg" },
  },
  benefit: {
    title: "WHAT DO YOU GET",
    description: "GET MORE BENEFITS",
    background: { url: "" },
    cards: [
      {
        image: { url: "images/content-creator/benefit/view.png" },
        description: "Flexible rewards system for views & channel growth",
      },
      {
        image: { url: "images/content-creator/benefit/star.png" },
        description: "Early access to upcoming updates content",
      },
      {
        image: { url: "images/content-creator/benefit/control.png" },
        description: "Tailor-made in-game assets for you",
      },
      {
        image: { url: "images/content-creator/benefit/gem.png" },
        description: "Fun challenges & support from the game team",
      },
      {
        image: { url: "images/content-creator/benefit/cc.png" },
        description: "Whole GV community to your support ",
      },
    ],
  },
  //TODO: Type 0 = Team pick
  bestVideos: {
    title: "best videos",
    description: "Team and editorial picks",
    background: { url: "" },
    videos: [
      {
        type: 1,
        visualUrl: { url: bgVideo },
        logo: { url: logoHall },
        name: "Gangstar Vegas video review",
        chanel: "Gangstar Vegas fanclub",
        infor: "171K subscribers",
      },
      {
        type: 0,
        visualUrl: { url: bgVideo },
        logo: { url: logoHall },
        name: "Gangstar Vegas video review",
        chanel: "Gangstar Vegas fanclub",
        infor: "171K subscribers",
      },
      {
        type: 1,
        visualUrl: { url: bgVideo },
        logo: { url: logoHall },
        name: "Gangstar Vegas video review",
        chanel: "Gangstar Vegas fanclub",
        infor: "171K subscribers",
      },
      {
        type: 0,
        visualUrl: { url: bgVideo },
        logo: { url: logoHall },
        name: "Gangstar Vegas video review",
        chanel: "Gangstar Vegas fanclub",
        infor: "171K subscribers",
      },
    ],
  },
  hallGlory: {
    title: "Hall of glory",
    description: "Selected video channels",
    listHall: [
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
      {
        logo: { url: logoHall },
        name: "Flux",
        infor: "171K subcribers",
      },
    ],
  },
  becomeCreator: {
    title: "HOW TO BECOME CREATOR",
    description: "BECOME CONTENT CREATOR IN 3 EASY STEPS",
    background: { url: "images/content-creator/become-creator/background.png" },
    cards: [
      {
        image: { url: "images/content-creator/become-creator/device.png" },
        description: "Create a video, tag #GangstarVegasCreator",
      },
      {
        image: { url: "images/content-creator/become-creator/video.png" },
        description: "Reach 2.000 views on YouTube",
      },
      {
        image: { url: "images/content-creator/become-creator/prize.png" },
        description: "Claim your gift and become a GV star",
      },
    ],
  },
  reward: {
    title: "REACH THE LEVEL",
    description: "MORE VIEWS - MORE REWARDS",
    button: "SUBMIT VIDEO",
    btnLink: "#submit",
    background: { url: "images/content-creator/reward/background.png" },
    beginner: { url: "images/content-creator/reward/beginner.png" },
    young: { url: "images/content-creator/reward/young.png" },
    real: { url: "images/content-creator/reward/real.png" },
    legendary: { url: "images/content-creator/reward/Legendary.png" },
  },
  submit: {
    title: "SUBMIT VIDEO",
    description: "SEND YOUR VIDEO USING THE FORM BELOW",
    background: { url: "images/content-creator/submit/background.png" },
    ageAgreement: `I confirm that I have reached the age of majority or that I am older than may age of majority in my country of residence.`,
    creatorAgreement: `I agree to the <a href="#top" target="_blank" rel="noopener noreferrer">Gangstar Vegas Creators Rules</a>.`,
    termAgreement: `I agree to Gameloft <a href="#top" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and I have read the <a href="#top" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.`,
  },
};
