import { ActionCreator } from "redux";
import { SystemAction } from "../reducers/systemReducer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import debounce from "lodash-es/debounce";
import store, { IBaseAction, StoreType } from "../index";
import { pick } from "lodash-es";

// action type define
export type SystemActionType = SystemAction_SetScreenSize | SystemAction_SetResponsive; // | any other action;

// Screen size
export interface SystemAction_SetScreenSize extends IBaseAction {
  type: SystemAction.SET_SCREEN_SIZE;
  payload: {
    width: number;
    height: number;
  };
}

export const systemAction_SetScreenSize: ActionCreator<SystemActionType> = (width: number, height: number) => {
  return {
    type: SystemAction.SET_SCREEN_SIZE,
    payload: { width, height },
  };
};

export const useScreensize = () => {
  const screensize = useSelector<StoreType, { width: number; height: number }>((store) => store.system.screenSize);
  return screensize;
};
export const useScreenWidth = () => {
  const width = useSelector<StoreType, number>((store) => store.system.screenSize.width);
  return width;
};

// Responsive
const mediaQueries = {
  mobile: "(max-width: 619px)",
  tablet: "(min-width: 620px)",
  laptop: "(min-width: 1024px) and (max-height: 780px)",
  desktop: "(min-width: 1024px)",
  "desktop-2k": "(min-width: 2348px)",
  "desktop-4k": "(min-width: 3500px)",
};
export type ResponsiveMedia = Record<keyof typeof mediaQueries, boolean>;
export interface SystemAction_SetResponsive extends IBaseAction {
  type: SystemAction.SET_RESPONSIVE_SIZE;
  payload: ResponsiveMedia;
}

export const systemAction_SetResponsive: ActionCreator<SystemActionType> = (responsive: ResponsiveMedia) => {
  return {
    type: SystemAction.SET_RESPONSIVE_SIZE,
    payload: responsive,
  };
};

export const useResponsive = (media: keyof ResponsiveMedia | (keyof ResponsiveMedia)[] | "all" | undefined = "all") => {
  const responsive = useSelector<StoreType, ResponsiveMedia | boolean | undefined>((store) => {
    if (media === undefined) return undefined;
    if (media === "all") return store.system.responsive;
    if (typeof media === "string") return store.system.responsive[media];
    return pick(store.system.responsive, media);
  });
  return responsive;
};

// register system itself
export const useSystem = () => {
  // register screen size change
  useEffect(() => {
    store.dispatch(systemAction_SetScreenSize(window.innerWidth, window.innerHeight)); // first time set
    const handleResize = debounce(() => {
      store.dispatch(systemAction_SetScreenSize(window.innerWidth, window.innerHeight));
    }, 250);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // init/register for media query
  useEffect(() => {
    const mediaQueryList: Record<string, MediaQueryList> = {};
    const handleQueryChange = () => {
      const matches: Record<string, boolean> = {};
      for (const media in mediaQueries) {
        matches[media] = mediaQueryList[media] && mediaQueryList[media].matches;
      }
      store.dispatch(systemAction_SetResponsive(matches));
    };

    if (window && window.matchMedia) {
      const matches: Record<string, boolean> = {};
      for (const media in mediaQueries) {
        mediaQueryList[media] = window.matchMedia((mediaQueries as any)[media]);
        matches[media] = mediaQueryList[media].matches;
        mediaQueryList[media].addListener(handleQueryChange);
      }
      store.dispatch(systemAction_SetResponsive(matches)); // first time set
    }

    return () => {
      for (const media in mediaQueries) {
        mediaQueryList[media]?.removeListener(handleQueryChange);
      }
    };
  }, []);
};
