import * as React from "react";
import "./FadeIn.scss";
import gsap from "gsap";
import "intersection-observer";
import { useMemo } from "react";

export interface FadeInProps {
  fromBelow?: boolean;
}

const FadeIn: React.FC<FadeInProps> = (props) => {
  const fadeRef = React.createRef<HTMLDivElement>();
  const [isVisible, setVisible] = React.useState(false);
  const [ran, setRan] = React.useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          setVisible(entry.isIntersecting);
        });
      }),
    []
  );

  React.useEffect(() => {
    const ref = fadeRef.current;
    const posY = props.fromBelow ? 80 : -80;
    const fadeIn = () => {
      if (!ran) {
        setRan(true);
        gsap.to(ref, {
          y: posY,
          opacity: 0,
          duration: 0,
        });
        gsap.to(ref, {
          y: 0,
          opacity: 1,
          duration: 0,
        });
      }
    };

    if (ref) observer.observe(ref as Element);
    if (isVisible) {
      fadeIn();
    }
    return () => {
      if (ref) observer.unobserve(ref as Element);
    };
  }, [fadeRef, isVisible, observer, ran, props.fromBelow]);

  return (
    <span ref={fadeRef} className="com-fade-in">
      {props.children}
    </span>
  );
};

export default FadeIn;
