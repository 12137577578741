import * as React from "react";
import "./NewsSection.scss";
import { useLocalize } from "../../../../redux/actions/localizeAction";
import LazyImage from "../../../../libs/lazy-load/LazyImage";
import News from "../../../news/News";
import { NavLink } from "react-router-dom";

export interface NewsSectionDataType {
  title: string;
  desciption: string;
  buttonText: string;
  buttonLink: string;
  background: SImage;
}

export interface NewsSectionProps {}

const NewsSection: React.FC<NewsSectionProps> = () => {
  const { data } = useLocalize<NewsSectionDataType>("home.news");

  return (
    <div className="com-news-section">
      <LazyImage className="com-news-section__background" src={data?.background?.url || ""}></LazyImage>
      <div className="com-news-section__title">{data?.title || ""}</div>
      <div className="com-news-section__description">{data?.desciption || ""}</div>
      <News></News>

      <NavLink to={data?.buttonLink || ""} className="com-news-section__more-news tablet">
        {data?.buttonText || ""}
      </NavLink>
    </div>
  );
};

export default NewsSection;
