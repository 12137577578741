import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../assets/images/header/logo-gsv.png";
import iosLogo from "../../assets/images/header/app-store-logo.png";
import googleLogo from "../../assets/images/header/google-play-logo.png";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { gsap } from "gsap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { SNS_LINKS, STORES, LEGAL_LINKS } from "../../utils";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/youtube.svg";
import { ReactComponent as DiscordIcon } from "../../assets/images/discord.svg";

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const mobileRef = React.createRef<HTMLDivElement>();
  const [animation, setAnimation] = useState<gsap.core.Tween>();
  const downloadLink = (() => {
    if (localStorage.getItem("ageGate") !== "pass") {
      return "/";
    } else {
      return "https://gmlft.co/GV-FA-WB";
    }
  })();
  useEffect(() => {
    if (mobileRef.current && animation === undefined) {
      setAnimation(
        gsap.to(mobileRef.current, 0.5, {
          x: 1500,
          paused: true,
        })
      );
    }
  }, [mobileRef, animation]);

  const moveMenu = () => {
    if (animation) {
      if (animation.progress() === 1) {
        animation.reverse();
      } else {
        animation.play();
      }
    }
  };

  return (
    <div className="com-header">
      <div className="game-icon">
        <NavLink to="/">
          <img src={logo} alt="Gangstar Vegas" />
        </NavLink>
      </div>
      <nav className="navigation-bar">
        <ul>
          <li>
            <NavLink to="/about" activeClassName="selected-link">
              ABOUT
            </NavLink>
          </li>
          <li>
            <NavLink to="/characters" activeClassName="selected-link">
              CHARACTERS
            </NavLink>
          </li>
          <li>
            <NavLink to="/map" activeClassName="selected-link">
              MAP
            </NavLink>
          </li>
          <li>
            <NavLink to="/community" activeClassName="selected-link">
              COMMUNITY
            </NavLink>
          </li>
          <li>
            <NavLink to="/content-creator" activeClassName="selected-link">
              Content Creator
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="dropdown">
        <a href={downloadLink} target="_blank" rel="noopener noreferrer">
          <button className="download-now seo-download-tracking-btn">Download Now</button>
        </a>
      </div>
      <div className="navigation-bar-select">
        <FontAwesomeIcon className="mobile-button-menu" onClick={moveMenu} icon={faBars} />
        <div className="mobile-menu" ref={mobileRef}>
          <FontAwesomeIcon icon={faTimes} className="x-button" onClick={moveMenu} />
          <NavLink to="/">
            <img src={logo} alt="Gangstar Vegas" className="mobile-game-icon" />
          </NavLink>
          <div className="mobile-nav-menu">
            <ul>
              <li>
                <NavLink to="/about" activeClassName="selected-link">
                  ABOUT
                </NavLink>
              </li>
              <li>
                <NavLink to="/characters" activeClassName="selected-link">
                  CHARACTERS
                </NavLink>
              </li>
              <li>
                <NavLink to="/map" activeClassName="selected-link">
                  MAP
                </NavLink>
              </li>
              <li>
                <NavLink to="/community" activeClassName="selected-link">
                  COMMUNITY
                </NavLink>
              </li>
              <li>
                <NavLink to="/content-creator" activeClassName="selected-link">
                  Content Creator
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="mobile-store-icon">
            <div className="google-icon">
              <a href={STORES.googleplay} target="_blank" rel="noopener noreferrer">
                <img src={googleLogo} alt="Google Play link" />
              </a>
            </div>
            <div className="apple-icon">
              <a href={STORES.appstore} target="_blank" rel="noopener noreferrer">
                <img src={iosLogo} alt="Apple Store link" />
              </a>
            </div>
          </div>
          <div className="mobile-contact-us">
            <span>
              <a href={LEGAL_LINKS.support} target="_blank" rel="noopener noreferrer">
                Customer Care
              </a>
            </span>
            <div className="mobile-sns-logo">
              <a className="facebook" href={SNS_LINKS.facebook} target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
              <a className="instagram" href={SNS_LINKS.instagram} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} color="#ffffff" size="3x" />
              </a>
              <a className="youtube" href={SNS_LINKS.youtube} target="_blank" rel="noopener noreferrer">
                <YoutubeIcon />
              </a>
              <a className="discord" href={SNS_LINKS.discord} target="_blank" rel="noopener noreferrer">
                <DiscordIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
