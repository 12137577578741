import React, { useEffect, Fragment } from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom";
import Home from "../views/home/Home";
import Synopsis from "../views/synopsis/Synopsis";
import Characters from "../views/characters/Characters";
import CityMap from "../views/map/CityMap";
import Community from "../views/community/Community";
import useReCaptcha3 from "../../utils/recaptcha/ReCaptchaV3";
import { RECAPTCHA_SITEKEY } from "../../utils";
import LegalNotice from "../views/legalnotices/LegalNotice";
import CookiePolicy from "../views/cookie/CookiePolicy";
import InnerNews from "../views/innernews/InnerNews";
import { Helmet } from "react-helmet";
import ContentCreator from "../views/contentcreator/ContentCreator";
import { useLanguage } from "../../redux/actions/localizeAction";
import { useSystem } from "../../redux/actions/systemAction";

const ScrollToTop: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

const App: React.FC = () => {
  // recaptcha load
  useReCaptcha3(RECAPTCHA_SITEKEY);
  useLanguage();
  useSystem();

  const checkAgeGate = () => {
    if (localStorage.getItem("ageGate") !== "pass") {
      return (
        <Fragment>
          <Route exact path="/">
            <Home />
          </Route>
          <Redirect to="/" />
        </Fragment>
      );
    }
  };

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <div className="com-app">
        <Helmet>
          <title>Gangstar Vegas | Welcome to the City of Sin!</title>
          <meta name="description" content="Download Gangstar Vegas today! A massive mobile open game world full of gang wars, theft, vice, auto racing, sniper action, clan conspiracies and more!" />
        </Helmet>
        <Switch>
          {checkAgeGate()}
          <Route path="/community" exact>
            <Community />
          </Route>
          <Route path="/news/" exact>
            <Community />
          </Route>
          <Route path="/news/:newsID?" exact>
            <InnerNews />
          </Route>
          <Route path="/map" exact>
            <CityMap />
          </Route>
          <Route path="/characters" exact>
            <Characters />
          </Route>
          <Route path="/about" exact>
            <Synopsis />
          </Route>
          <Route path="/legal" exact>
            <LegalNotice />
          </Route>
          <Route path="/cookie" exact>
            <CookiePolicy />
          </Route>
          <Route path="/content-creator" exact>
            <ContentCreator />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
