import * as React from "react";
import "./TopContainer.scss";
import Switch from "../switch/Switch";
import NewsTab from "../news-tab/NewsTab";
import { useState } from "react";
import CommunityFeed from "../community-feed/CommunityFeed";

export interface TopContainerProps {}

const TopContainer: React.FC<TopContainerProps> = (props) => {
  const [newsOpen, setNewsOpen] = useState<boolean>(true);

  const handleSwitch = () => {
    setNewsOpen((state) => (state = !state));
  };

  return (
    <div className="com-top-container">
      <Switch onSwitch={handleSwitch} />
      <div className={`com-top-container__news${newsOpen ? "" : " hide"}`}>
        <NewsTab />
      </div>
      <div className={`com-top-container__feed${newsOpen ? " hide" : ""}`}>
        <CommunityFeed />
      </div>
    </div>
  );
};

export default TopContainer;
