import * as React from "react";
import "./News.scss";
import NewsCard, { NewsDataType } from "./newscard/NewsCard";
import ScrollMenu from "../scrollmenu/ScrollMenu";
import { ReactComponent as LoadingSpin } from "../../assets/images/loading.svg";
import { TriggerPoint } from "../../utils";
import { useLocalize } from "../../redux/actions/localizeAction";
import { NEWS_QUERY } from "../../query/news";

export interface NewsProps {}

const News: React.FC<NewsProps> = (props) => {
  const { data } = useLocalize<NewsDataType[]>("news", {
    api: "/graphql",
    method: "POST",
    postData: { query: NEWS_QUERY },
    selector: "data.newsList.NewsCard",
  });

  const customBreak = {
    breakpoint: 674,
    settings: {
      centerPadding: "0",
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  };

  return (
    <div className={"com-news"}>
      {data ? (
        <ScrollMenu
          currentLocation={TriggerPoint.NEWS}
          slidesForTablet={3}
          settings={{
            initialSlide: 0,
          }}
          customBreaks={[customBreak]}
        >
          {data
            ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            ?.filter((news) => news.visible)
            .map((card, index) => {
              return <NewsCard data={card} key={index} />;
            })}
        </ScrollMenu>
      ) : (
        <LoadingSpin className="com-news__loading-spin" />
      )}
    </div>
  );
};

export default News;
