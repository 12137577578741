import EActionTypes from "../actions/actionTypes";
import { FormAction } from "../actions/formAction";

export interface IFormState {
  isSuccess: boolean;
  isFetching: boolean;
  isInvalidate: boolean;
}

const initialState: IFormState = {
  isSuccess: false,
  isFetching: false,
  isInvalidate: false
};

const formReducer = (state: IFormState = initialState, action: FormAction) => {
  switch (action.type) {
    case EActionTypes.SUBMIT_FORM_POST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        isInvalidate: false
      };
    case EActionTypes.SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isFetching: false,
        isInvalidate: true,
        isSuccess: false
      };
    case EActionTypes.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isInvalidate: false,
        isSuccess: true
      };
    case EActionTypes.SUBMIT_FORM_RESET:
      return {
        ...state,
        isFetching: false,
        isInvalidate: false,
        isSuccess: false
      };
    default:
      return state;
  }
};

export default formReducer;
