import * as React from "react";
import "./InnerNews.scss";
import Header from "../../header/Header";
import NewsContent from "./news-content/NewsContent";
import MoreGames from "../../moregames/MoreGames";
import Footer from "../../footer/Footer";
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from "../../../utils";
import { Redirect, useLocation } from "react-router-dom";
import { ReactComponent as LoadingSpin } from "../../../assets/images/loading.svg";
import { Helmet } from "react-helmet";
import { useLocalize } from "../../../redux/actions/localizeAction";
import { NewsDataType } from "../../news/newscard/NewsCard";
import { NEWS_QUERY } from "../../../query/news";

export interface InnerNewsProps {}

const InnerNews: React.FC<InnerNewsProps> = () => {
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { data } = useLocalize<NewsDataType[]>("news", {
    api: "/graphql",
    method: "POST",
    postData: { query: NEWS_QUERY },
    selector: "data.newsList.NewsCard",
  });

  const displayCard = data?.find((card) => {
    return card.slug === id;
  });

  if (data && !displayCard?.visible) return <Redirect to="/" />;

  return (
    <div className="com-inner-news">
      <Helmet>
        <title>{displayCard?.metaTitle || DEFAULT_TITLE}</title>
        <meta name="description" content={displayCard?.metaDescription || DEFAULT_DESCRIPTION} />
      </Helmet>
      <Header />
      {displayCard ? (
        <NewsContent card={displayCard} />
      ) : (
        <div className="com-inner-news__loading">
          <LoadingSpin />
        </div>
      )}
      <MoreGames />
      <Footer />
    </div>
  );
};

export default InnerNews;
