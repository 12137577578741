import EActionTypes, { IBaseAction } from "./actionTypes";
import { BackTo } from "../reducers/navReducer";

export interface INavSwitchAction extends IBaseAction {
  type: EActionTypes;
  payload: boolean;
}

export type NavAction = INavSwitchAction;

export const signalScrollBack = (signal: boolean) => {
  return {
    type: EActionTypes.SWITCH_SCROLL_BACK_STATUS,
    payload: signal
  };
};

export const updateBackToLocation = (location: BackTo) => {
  return {
    type: EActionTypes.UPDATE_BACK_TO_LOCATION,
    payload: location
  };
};
