import * as React from "react";
import "./CookiePolicy.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

export interface CookiePolicyProps {}

const CookiePolicy: React.FC<CookiePolicyProps> = () => {
  return (
    <div className="com-cookie-policy">
      <Header />
      <div className="content">
        <h1 className="text-uppercase">GAMELOFT COOKIES POLICY</h1>

        <p>Your installation and/or use of these Websites/Apps and/or submission of information to us constitute your consent to this Cookies Policy and the processing of your Personal Data. This Cookies Policy is effective as of April 1, 2018.</p>
        <p></p>
        <h2>Introduction</h2>
        <p>This Cookies Policy explains what cookies are, how Gameloft uses cookies on our websites and our mobile apps, and what you can do to manage how cookies are used.</p>
        <p>We are committed to protecting the Personal Data we collect when you use our websites, mobile apps and other services. We are informing you in a transparent way about our policy regarding the deposit and use of cookies from our sites to your connection terminal.</p>
        <p></p>
        <h2>What is a cookie?</h2>
        <p>
          Cookies are small text files that are placed and stored on your computer, smartphone or other Internet-optimized device when you visit a website. Cookies are very useful and allow a website to recognize you, to signal your passage on this or that page, and to provide you with additional
          services: improving your navigational comfort, securing your connection or adapting the contents of a page to your interests. The information stored by the cookies, which are valid for a limited period of time, relates in particular to the pages visited, the advertisements on which you
          have clicked, the type of browser you use, and the information you have entered on a site in order to avoid having to enter it again.
        </p>
        <p>
          We may use cookies or similar technologies (tiny files that are stored on your computer's hard drive or mobile device that identify your computer or mobile device when you access certain web pages or apps and may store information about you, such as behavioral data). Should you choose to
          submit Personal Data to us, we may link cookie information to such Personal Data. In that case, you can exert your privacy rights as specified in{" "}
          <a href="https://www.gameloft.com/en/privacy-notice" target="_blank" rel="noopener noreferrer">
            Gameloft's Privacy Policy
          </a>
          .
        </p>
        <p></p>
        <h2>What types of cookies do we use?</h2>
        <div className="cookie-tbl pt1">
          <table>
            <thead>
              <tr className="text-uppercase">
                <th>COOKIES (functionality summary)</th>
                <th>NAME</th>
                <th>DESCRIPTION</th>
                <th>Cookie Lifetime</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MULTIMEDIA CONTENT &amp; PLAYER COOKIES</td>
                <td>Isp</td>
                <td>Used to record network speed, image quality or buffer information for the duration of the session.</td>
                <td>1 month</td>
              </tr>
              <tr>
                <td>UI CUSTOMIZATION COOKIES</td>
                <td>lang</td>
                <td>Used to store preferences, such as language choice, for the duration of the session.</td>
                <td>1 month</td>
              </tr>
              <tr>
                <td>ANALYTICS COOKIES / AUDIENCE MEASUREMENT</td>
                <td>
                  _ga
                  <br />
                  _gid
                  <br />
                  _gat
                </td>
                <td>Used to obtain information about visitor navigation and produce anonymous statistics.</td>
                <td>
                  1 year - used to distinguish users
                  <br />
                  24 hours – used to distinguish users
                  <br />1 minute – used to throttle request rate (if Google Analytics is deployed via Google Tag Manager)
                </td>
              </tr>
              <tr>
                <td>SOCIAL MEDIA COOKIES (FACEBOOK, TWITTER, ETC.)</td>
                <td>N/A</td>
                <td>
                  <p>
                    We integrate links to Facebook, Twitter, Google+ and other social networks onto our websites' pages.
                    <br />
                    Social networks are likely to identify you through these links, even if you did not use them when you viewed our sites.
                    <br />
                    To access the privacy policies of the various social networks included on our sites, and to disable their ability to trace you, we invite you to go to the following.
                  </p>

                  <p>Example:</p>

                  <ul>
                    <li style={{ textAlign: "left" }}>
                      For Facebook:{" "}
                      <a href="https://www.facebook.com/policies/cookies/?ref=hc_fnav" target="_blank" rel="noopener noreferrer">
                        https://www.facebook.com/policies/cookies/?ref=hc_fnav
                      </a>
                    </li>
                  </ul>
                </td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p></p>

        <h2>
          <u>How do I accept or reject cookies?&nbsp;</u>
        </h2>

        <p>
          <strong>You can choose to disable or enable cookies at any time in your browser.&nbsp;</strong>
        </p>

        <p>Your browser can be set to notify you of the cookies that are deposited on your computer and ask you to accept them or not. You can accept or reject cookies on a case-by-case basis or reject them consistently once and for all.</p>

        <p>
          We remind you that the refusal of cookies is likely to modify your conditions of access to our Sites as well as their use. In order to manage the cookies to meet your expectations as closely as possible, we invite you to set your browser while taking into account the purpose of the cookies
          as mentioned above.
          <br />
          The configuration of each browser is different with regard to the management of the cookies and your choices. We suggest consulting the Help section of your browser or taking a look at the website{" "}
          <a href="https://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">
            AboutCookies.org
          </a>
          ,&nbsp;which offers guidance for all modern browsers. You can also configure your preferences, depending on your browser, by clicking on the links that are listed.
        </p>

        <ul className="no-deco">
          <li>
            For Internet Explorer:{" "}
            <a href="http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies" target="_blank" rel="noopener noreferrer">
              http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies
            </a>
          </li>
          <li>
            For Safari:{" "}
            <a href="http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html" target="_blank" rel="noopener noreferrer">
              http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html
            </a>
          </li>
          <li>
            For Chrome:{" "}
            <a href="http://support.google.com/chrome/bin/answer.py?hl=en&amp;hlrm=en&amp;answer=95647" target="_blank" rel="noopener noreferrer">
              http://support.google.com/chrome/bin/answer.py?hl=en&amp;hlrm=en&amp;answer=95647
            </a>
          </li>
          <li>
            For Firefox:{" "}
            <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener noreferrer">
              https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            </a>
          </li>
          <li>
            For Opera:{" "}
            <a href="http://help.opera.com/Windows/10.20/en/cookies.html" target="_blank" rel="noopener noreferrer">
              http://help.opera.com/Windows/10.20/en/cookies.html
            </a>
          </li>
        </ul>

        <p>
          You can manage your cookies by visiting centralized cookie management platforms, offered by advertising professionals, by logging into the [Youronlinechoices] website, which is offered by the digital advertising professionals grouped under the EDAA (European Interactive Digital Advertising
          Alliance) and managed by the IABF (Interactive Advertising Bureau France).
        </p>

        <p>You will be able to see the companies registered on this platform and refuse or accept cookies that will probably be used to adapt the advertisements likely to be displayed there to your computer's navigation:&nbsp;http://www.youronlinechoices.com/en/controlling-its-cookies/.</p>

        <p>
          <u>Configuration differs depending on your mobile device or tablet.</u>
        </p>

        <ul>
          <li>
            For iOS:{" "}
            <a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer">
              https://support.apple.com/en-us/HT201265
            </a>
          </li>
          <li>
            For Android:{" "}
            <a href="http://www.wikihow.com/Disable-Cookies#Android_Devices" target="_blank" rel="noopener noreferrer">
              http://www.wikihow.com/Disable-Cookies#Android_Devices
            </a>
          </li>
          <li>
            For Blackberry:{" "}
            <a href="http://global.blackberry.com/en/legal/privacy-policy.html#types-info-d" target="_blank" rel="noopener noreferrer">
              http://global.blackberry.com/en/legal/privacy-policy.html#types-info-d
            </a>
          </li>
          <li>
            For Windows Phone:{" "}
            <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">
              https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </li>
        </ul>

        <h2>Limit Ad Tracking / Do Not Track</h2>

        <p>You can set your computer’s browser so that it sends a code indicating to websites that you do not want to be tracked. This option is commonly known as "Do Not Track."</p>

        <ul>
          <li>
            For Internet Explorer:{" "}
            <a href="http://windows.microsoft.com/en-us/internet-explorer/use-tracking-protection#ie=ie-11" target="_blank" rel="noopener noreferrer">
              http://windows.microsoft.com/en-us/internet-explorer/use-tracking-protection#ie=ie-11
            </a>
          </li>
          <li>
            For Safari:{" "}
            <a href="http://support.apple.com/kb/PH11952" target="_blank" rel="noopener noreferrer">
              http://support.apple.com/kb/PH11952
            </a>
          </li>
          <li>
            For Chrome:{" "}
            <a href="https://support.google.com/chrome/answer/114836" target="_blank" rel="noopener noreferrer">
              https://support.google.com/chrome/answer/114836
            </a>
          </li>
          <li>
            For Firefox:{" "}
            <a href="https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies" target="_blank" rel="noopener noreferrer">
              https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies
            </a>
          </li>
          <li>
            For Opera:{" "}
            <a href="http://help.opera.com/Windows/12.10/en/notrack.html" target="_blank" rel="noopener noreferrer">
              http://help.opera.com/Windows/12.10/en/notrack.html
            </a>
          </li>
        </ul>

        <p>You can also set your mobile devices to limit ad tracking or reinitialize your advertising identifier.</p>

        <ul>
          <li>
            For iOS:{" "}
            <a href="https://support.apple.com/en-gb/HT202074" target="_blank" rel="noopener noreferrer">
              https://support.apple.com/en-gb/HT202074
            </a>
          </li>
          <li>
            For Android:{" "}
            <a href="https://support.google.com/ads/answer/2662922?hl=en" target="_blank" rel="noopener noreferrer">
              https://support.google.com/ads/answer/2662922?hl=en
            </a>
          </li>
          <li>
            For Blackberry:{" "}
            <a href="http://global.blackberry.com/en/legal/privacy-policy.html#types-info-d" target="_blank" rel="noopener noreferrer">
              http://global.blackberry.com/en/legal/privacy-policy.html#types-info-d
            </a>
          </li>
          <li>
            For Windows Phone:{" "}
            <a href="https://account.microsoft.com/privacy/ad-settings/signedout?ru=https:%2F%2Faccount.microsoft.com%2Fprivacy%2Fad-settings" target="_blank" rel="noopener noreferrer">
              https://account.microsoft.com/privacy/ad-settings/signedout?ru=https:%2F%2Faccount.microsoft.com%2Fprivacy%2Fad-settings
            </a>
          </li>
        </ul>

        <h2>Changes</h2>

        <p>
          We reserve the right to change this Cookies Policy at any time. In the event that we make material changes to this Cookies Policy, it will be re-posted in the "Cookies" section of our Websites with the date the modifications were made indicated at the top of the page. Therefore, please
          review this Cookies Policy from time to time so that you are aware when any changes are made to it. Your continued use of the Websites after such change(s) constitutes your acceptance of any such change(s), and if you do not accept any changes, you may choose not to use the Websites.&nbsp;
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
