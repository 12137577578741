import "./Creator.scss";
import * as React from "react";
import StaticBanner, { StaticBannerDataType } from "../../../staticbanner/StaticBanner";
import { useLocalize } from "../../../../redux/actions/localizeAction";

export interface CreatorProps {}

const Creator: React.FC<CreatorProps> = () => {
  const { data } = useLocalize<StaticBannerDataType>("home.creator");
  return (
    <div className="com-creator">
      <StaticBanner data={data} />
    </div>
  );
};

export default Creator;
