import * as React from "react";
import "./Reward.scss";
import { useLocalize } from "../../../../redux/actions/localizeAction";
import LazyImage from "../../../../libs/lazy-load/LazyImage";
import FadeIn from "../../../fadein/FadeIn";
import { MediaDataType } from "../../../../utils";

export interface RewardDataType {
  title: string;
  description: string;
  background: MediaDataType;
  button: string;
  btnLink: string;
  beginner: MediaDataType;
  young: MediaDataType;
  real: MediaDataType;
  legendary: MediaDataType;
}

export interface RewardProps {}

const Reward: React.FC<RewardProps> = () => {
  const { data } = useLocalize<RewardDataType>("contentCreator.reward");

  return (
    <div className="com-reward" id="reward">
      <LazyImage className="com-reward__background desktop" src={data?.background.url || ""} />
      <FadeIn>
        <div className="com-reward__title">{data?.title || ""}</div>
        <div className="com-reward__description">{data?.description || ""}</div>
        <div className="com-reward__image-container">
          <LazyImage className="com-reward__image" src={data?.beginner.url || ""} />
          <LazyImage className="com-reward__image" src={data?.young.url || ""} />
          <LazyImage className="com-reward__image" src={data?.real.url || ""} />
          <LazyImage className="com-reward__image" src={data?.legendary.url || ""} />
        </div>
      </FadeIn>
    </div>
  );
};

export default Reward;
