enum EActionTypes {
  ERROR = "ERROR", // error foward
  ERROR_CLEAR = "ERROR_CLEAR", // clear error

  GET_USERS = "GET_USERS", // get use list
  GET_USER = "GET_USER", // get single user info

  SWITCH_VIEW = "SWITCH_VIEW", // Enter image view mode
  UPDATE_SLIDE_INDEX = "UPDATE_SLIDE_INDEX", //Update the index of the clicked on slide for large view mode
  UPDATE_IN_VIEW_SLIDE_INDEX = "UPDATE_IN_VIEW_SLIDE_INDEX", //Update the index of a slide in view
  UPDATE_SLIDE_ID = "UPDATE_SLIDE_ID", //SAme as SLIDE INDEX, used for news with post_name as id

  SWITCH_SCROLL_BACK_STATUS = "SWITCH_SCROLL_BACK_STATUS", //Signal to scroll back to previous section when go back to Home Page
  UPDATE_BACK_TO_LOCATION = "UPDATE_BACK_TO_LOCATION", //Update location to back to when viewing news (to Home or to News Page)

  SUBMIT_FORM_POST = "SUBMIT_FORM_POST",
  SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS",
  SUBMIT_FORM_FAILURE = "SUBMIT_FORM_FAILURE",
  SUBMIT_FORM_RESET = "SUBMIT_FORM_RESET",

  FETCH_FAILURE = "FETCH_FAILURE",
  FETCH_SUCCESS = "FETCH_SUCCESS",
  FETCH_REQUEST = "FETCH_REQUEST",
  FETCH_APPEND = "FETCH_APPEND"
}

export interface IBaseAction {
  type: EActionTypes;
}

export default EActionTypes;
