import axios from "./index";
import { SignupFormData } from "../components/signup/Signup";
import { VideoFormData } from "../components/views/contentcreator/submit-video/SubmitVideo";
// import Axios from "axios";
// const axiosInstance = Axios.create({
//   baseURL: "http://gangstarvegas-be.gwmt-staging.k01.gameloft.org/api/",
//   responseType: "json"
// });

const api = "subscription";

type FormData = SignupFormData | VideoFormData;

export const postFormData = (
  postData: FormData,
  path?: string,
  name?: string
) => {
  return axios.post(path ? path : api, postData, { name: name });
};
