export const HOME_QUERY = `{
  home{
    video{
      videoUrl,
      storeLeftUrl,
      storeLeftIcon{url},
      storeRightUrl,
      storeRightIcon{url}
    },
    action{
      headerTitleMobile,
      headerTitleDesktop,
      headerDescription,
      footerTitleMobile,
      footerTitleDesktop,
      footerDescription,
      buttonUrl,
      buttonText
      cards{
        image{url},
        title,
        description
      }
    },
    creator{
      title,
      description,
      buttonText,
      buttonUrl,
      background{url},
      character{url}
    }
    news{
      title,
      desciption,
      buttonText,
      buttonLink,
      background{url}
    }
    feed{
      title,
      description,
    },
    newsletter{
      title,
      description,
      name,
      nameError,
      lastname,
      lastnameError,
      email,
      emailError,
      birthdate,
      birthdateError,
      term,
      termError,
      platform,
      newsletterAgreement,
      newsletterGameloft,
      buttonText
    }
  }
}`;
