import * as React from "react";
import "./NewsContent.scss";
import { NewsDataType } from "../../../news/newscard/NewsCard";
import { forwardRef } from "react";
import { generateShareLinkTo, MediaType, triggerPopup } from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import dompurify from "dompurify";
import { NavLink } from "react-router-dom";
import LazyImage from "../../../../libs/lazy-load/LazyImage";

export interface NewsContentProps {
  card: NewsDataType;
}

const NewsContent = forwardRef<any, NewsContentProps>(({ card, ...props }, ref) => {
  const sanitizer = dompurify.sanitize;

  const youtube = "https://www.youtube.com/embed/" + card.videoUrl + "?controls=0&rel=0";
  const facebookShareLink = generateShareLinkTo(MediaType.Facebook, `u=https://gangstar-vegas.com/${card.slug}`);

  const twitterShareLink = generateShareLinkTo(MediaType.Twitter, `text=${card.title} https://gangstar-vegas.com/${card.slug}&original_referer=https://gangstar-vegas.com`);
  const media = (() => {
    return card.videoUrl !== "" && card.videoUrl ? (
      <div className="video-container">
        <iframe title={card.title} width="100%" height="100%" src={youtube} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    ) : null;
  })();

  return (
    <div ref={ref} className="com-view-news-popup">
      <LazyImage src={card.hotspot?.url || ""} alt={card.title + " image"} className="hotspot" />
      <div className="nav-link">
        <NavLink to="/community">News</NavLink>
        <span> {">"} </span>
        <NavLink to={"/news/" + card.slug}>{card.title}</NavLink>
      </div>
      <div className="body-container">
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizer(card.title),
          }}
          className="header"
        ></div>
        <p className="date">{"By Gangstar | " + card.date}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizer(card.content, {
              ADD_TAGS: ["iframe"],
              ADD_ATTR: ["target", "rel"],
            }),
          }}
          className="description"
        ></div>
        {media}
        <div className="footer">
          <div className="share-section">
            <span className="share-it">Share it</span>
            <div className="sns-icons">
              <FontAwesomeIcon
                onClick={() => {
                  triggerPopup(facebookShareLink);
                }}
                icon={faFacebookF}
              />
              <FontAwesomeIcon
                onClick={() => {
                  triggerPopup(twitterShareLink);
                }}
                icon={faTwitter}
              />
            </div>
          </div>
          <NavLink className="back-button" to={"/community"}>
            BACK TO NEWS
          </NavLink>
        </div>
      </div>
    </div>
  );
});

export default NewsContent;
