import React, { useEffect } from "react";
import "./Home.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Video from "../../video/Video";
import Action from "../../action/Action";
import Signup from "../../signup/Signup";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../../redux/reducers";
import { INavState, BackTo } from "../../../redux/reducers/navReducer";
import { signalScrollBack } from "../../../redux/actions/navAction";
import AgeGate from "../../agegate/AgeGate";
import { IViewState } from "../../../redux/reducers/viewReducer";
import { ViewMode } from "../../../redux/actions/viewAction";
import Creator from "./creator/Creator";
import { useLocalize } from "../../../redux/actions/localizeAction";
import NewsSection from "./news-section/NewsSection";
import { useSystem } from "../../../redux/actions/systemAction";
import FeedSection from "./feedsection/feed-section";
import { HOME_QUERY } from "../../../query/home";

const Home: React.FC = () => {
  const nav = useSelector<StoreState, INavState>((state) => state.mobileNewsNav);
  const view = useSelector<StoreState, IViewState>((state) => state.homeView);
  const homeRef = React.createRef<HTMLDivElement>();
  const popupRef = React.createRef<HTMLDivElement>();
  const dispatch = useDispatch();
  useLocalize("home", { method: "POST", postData: { query: HOME_QUERY }, api: "/graphql", selector: "data.home" });
  useSystem();

  useEffect(() => {
    if (nav.needScrollBack && nav.backTo === BackTo.HOME) {
      document.getElementById("scroll-menu-header")?.scrollIntoView();
      dispatch(signalScrollBack(false));
    }
  }, [nav.needScrollBack, dispatch, nav.backTo]);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("ageGate") === "pass" && homeRef.current) {
        homeRef.current.style.overflow = "initial";
        homeRef.current.style.height = "initial";
      }
    }, 1);
  });

  //Active/deactive view news popup
  useEffect(() => {
    if (popupRef.current) {
      if (view.mode === ViewMode.LARGE) {
        popupRef.current.style.display = "block";
        popupRef.current.scrollIntoView();
        window.scrollBy(0, -150);
      } else popupRef.current.style.display = "none";
    }
  });

  return (
    <div ref={homeRef} className="com-home">
      <AgeGate />
      <Header />
      <Video />
      <Action />
      <Creator />
      <NewsSection />
      <FeedSection />
      <Signup />
      <Footer />
    </div>
  );
};

export default Home;
