import * as React from "react";
import "./Character.scss";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import CharacterSlide from "../../characterslide/CharacterSlide";

export interface CharactersProps {}

const Characters: React.FC<CharactersProps> = () => {
  return (
    <div className="com-characters">
      <Header />
      <CharacterSlide />
      <Footer />
    </div>
  );
};

export default Characters;
