import * as React from "react";

export const tokenizeString = (view: string, delim: string): string[] => {
  if (!view) return [];

  let result: string[] = view.replace(/\s/g, "").split(delim);

  return result;
};

// import MockNewsImage from "../assets/images/news/mock-news.png";
// import MockNewsLargeImage from "../assets/images/news/mock-news-large.png";
// import MockNewsMediumImage from "../assets/images/news/mock-news-medium.png";

// this files will export all other untils
// ex: export {xxx} from './yyy';
enum MediaType {
  All = "all",
  Facebook = "facebook",
  Twitter = "twitter",
  Instagram = "instagram",
  Discord = "discord",
  Youtube = "youtube",
  Undefined = "undefined",
}

export interface MediaDataType {
  url: string;
}

export enum TriggerPoint {
  FEED = "FEED",
  NEWS = "NEWS",
  CHARACTERS = "CHARACTERS",
  HOME = "HOME",
  NONE = "NONE",
  MAP = "MAP",
  GAMES = "GAMES",
}

//SNS links
export const SNS_LINKS = {
  youtube: "https://www.youtube.com/channel/UCSg0zBd27vyYqaBPEru0ipw",
  instagram: "https://www.instagram.com/playgangstar/",
  discord: "https://discordapp.com/invite/ymnBjPE",
  facebook: "https://www.facebook.com/playgangstar/",
};

//Store links
export const STORES = {
  appstore: "https://apps.apple.com/us/app/gangstar-vegas/id571393580",
  googleplay: "https://play.google.com/store/apps/details?id=com.gameloft.android.ANMP.GloftGGHM",
};

//Legal links
export const LEGAL_LINKS = {
  support: "https://gameloft.helpshift.com/hc/en/26-gangstar-vegas/",
  privacy: "https://www.gameloft.com/en/privacy-notice",
  terms: "https://www.gameloft.com/en/conditions-of-use",
  cookies: "/cookie",
  legal: "/legal",
  eula: "https://www.gameloft.com/en/eula",
  gameloft: "https://www.gameloft.com",
};

// ReCaptcha key
export const RECAPTCHA_SITEKEY = "6LcQJtgUAAAAAGc6jmaQP3VXotWNN3fQeDP1oN1V";

// Meta data
export const DEFAULT_TITLE = "Gangstar Vegas | Welcome to the City of Sin!";
export const DEFAULT_DESCRIPTION = "Download Gangstar Vegas today! A massive mobile open game world full of gang wars, theft, vice, auto racing, sniper action, clan conspiracies and more!";

export const COUNTRIES = [
  { value: "af", name: "Afghanistan" },
  { value: "al", name: "Albania" },
  { value: "dz", name: "Algeria" },
  { value: "as", name: "American Samoa" },
  { value: "ad", name: "Andorra" },
  { value: "ao", name: "Angola" },
  { value: "ai", name: "Anguilla" },
  { value: "ag", name: "Antigua & Barbuda" },
  { value: "ar", name: "Argentina" },
  { value: "am", name: "Armenia" },
  { value: "aw", name: "Aruba" },
  { value: "au", name: "Australia" },
  { value: "at", name: "Austria" },
  { value: "az", name: "Azerbaijan" },
  { value: "bs", name: "Bahamas" },
  { value: "bh", name: "Bahrain" },
  { value: "bd", name: "Bangladesh" },
  { value: "bb", name: "Barbados" },
  { value: "by", name: "Belarus" },
  { value: "be", name: "Belgium" },
  { value: "bz", name: "Belize" },
  { value: "bj", name: "Benin" },
  { value: "bm", name: "Bermuda" },
  { value: "bt", name: "Bhutan" },
  { value: "bo", name: "Bolivia" },
  { value: "ba", name: "Bosnia & Herzegovina" },
  { value: "bw", name: "Botswana" },
  { value: "br", name: "Brazil" },
  { value: "vg", name: "British Virgin Islands" },
  { value: "bn", name: "Brunei" },
  { value: "bg", name: "Bulgaria" },
  { value: "bf", name: "Burkina Faso" },
  { value: "bi", name: "Burundi" },
  { value: "kh", name: "Cambodia" },
  { value: "cm", name: "Cameroon" },
  { value: "ca", name: "Canada" },
  { value: "cv", name: "Cape Verde" },
  { value: "ky", name: "Cayman Islands" },
  { value: "cf", name: "Central African Republic" },
  { value: "td", name: "Chad" },
  { value: "cl", name: "Chile" },
  { value: "cn", name: "China" },
  { value: "cx", name: "Christmas Island" },
  { value: "cc", name: "Cocos (Keeling) Islands" },
  { value: "co", name: "Colombia" },
  { value: "km", name: "Comoros" },
  { value: "cg", name: "Congo -- Brazzaville" },
  { value: "ck", name: "Cook Islands" },
  { value: "cr", name: "Costa Rica" },
  { value: "hr", name: "Croatia" },
  { value: "cu", name: "Cuba" },
  { value: "cy", name: "Cyprus" },
  { value: "cz", name: "Czech Republic" },
  { value: "ci", name: "Côte d'Ivoire" },
  { value: "dk", name: "Denmark" },
  { value: "dj", name: "Djibouti" },
  { value: "dm", name: "Dominica" },
  { value: "do", name: "Dominican Republic" },
  { value: "ec", name: "Ecuador" },
  { value: "eg", name: "Egypt" },
  { value: "sv", name: "El Salvador" },
  { value: "gq", name: "Equatorial Guinea" },
  { value: "er", name: "Eritrea" },
  { value: "ee", name: "Estonia" },
  { value: "et", name: "Ethiopia" },
  { value: "fk", name: "Falkland Islands" },
  { value: "fo", name: "Faroe Islands" },
  { value: "fj", name: "Fiji" },
  { value: "fi", name: "Finland" },
  { value: "fr", name: "France" },
  { value: "gf", name: "French Guiana" },
  { value: "pf", name: "French Polynesia" },
  { value: "ga", name: "Gabon" },
  { value: "gm", name: "Gambia" },
  { value: "ge", name: "Georgia" },
  { value: "de", name: "Germany" },
  { value: "gh", name: "Ghana" },
  { value: "gi", name: "Gibraltar" },
  { value: "gr", name: "Greece" },
  { value: "gl", name: "Greenland" },
  { value: "gd", name: "Grenada" },
  { value: "gp", name: "Guadeloupe" },
  { value: "gu", name: "Guam" },
  { value: "gt", name: "Guatemala" },
  { value: "gg", name: "Guernsey" },
  { value: "gn", name: "Guinea" },
  { value: "gw", name: "Guinea-Bissau" },
  { value: "gy", name: "Guyana" },
  { value: "ht", name: "Haiti" },
  { value: "hn", name: "Honduras" },
  { value: "hk", name: "Hong Kong SAR China" },
  { value: "hu", name: "Hungary" },
  { value: "is", name: "Iceland" },
  { value: "in", name: "India" },
  { value: "id", name: "Indonesia" },
  { value: "ir", name: "Iran" },
  { value: "iq", name: "Iraq" },
  { value: "ie", name: "Ireland" },
  { value: "im", name: "Isle of Man" },
  { value: "il", name: "Israel" },
  { value: "it", name: "Italy" },
  { value: "jm", name: "Jamaica" },
  { value: "jp", name: "Japan" },
  { value: "je", name: "Jersey" },
  { value: "jo", name: "Jordan" },
  { value: "kz", name: "Kazakhstan" },
  { value: "ke", name: "Kenya" },
  { value: "ki", name: "Kiribati" },
  { value: "kw", name: "Kuwait" },
  { value: "kg", name: "Kyrgyzstan" },
  { value: "la", name: "Laos" },
  { value: "lv", name: "Latvia" },
  { value: "lb", name: "Lebanon" },
  { value: "ls", name: "Lesotho" },
  { value: "lr", name: "Liberia" },
  { value: "ly", name: "Libya" },
  { value: "li", name: "Liechtenstein" },
  { value: "lt", name: "Lithuania" },
  { value: "lu", name: "Luxembourg" },
  { value: "mo", name: "Macau SAR China" },
  { value: "mk", name: "Macedonia" },
  { value: "mg", name: "Madagascar" },
  { value: "mw", name: "Malawi" },
  { value: "my", name: "Malaysia" },
  { value: "mv", name: "Maldives" },
  { value: "ml", name: "Mali" },
  { value: "mt", name: "Malta" },
  { value: "mh", name: "Marshall Islands" },
  { value: "mq", name: "Martinique" },
  { value: "mr", name: "Mauritania" },
  { value: "mu", name: "Mauritius" },
  { value: "yt", name: "Mayotte" },
  { value: "mx", name: "Mexico" },
  { value: "fm", name: "Micronesia" },
  { value: "md", name: "Moldova" },
  { value: "mc", name: "Monaco" },
  { value: "mn", name: "Mongolia" },
  { value: "me", name: "Montenegro" },
  { value: "ms", name: "Montserrat" },
  { value: "ma", name: "Morocco" },
  { value: "mz", name: "Mozambique" },
  { value: "na", name: "Namibia" },
  { value: "nr", name: "Nauru" },
  { value: "np", name: "Nepal" },
  { value: "nl", name: "Netherlands" },
  { value: "nc", name: "New Caledonia" },
  { value: "nz", name: "New Zealand" },
  { value: "ni", name: "Nicaragua" },
  { value: "ne", name: "Niger" },
  { value: "ng", name: "Nigeria" },
  { value: "nu", name: "Niue" },
  { value: "nf", name: "Norfolk Island" },
  { value: "mp", name: "Northern Mariana Islands" },
  { value: "no", name: "Norway" },
  { value: "om", name: "Oman" },
  { value: "pw", name: "Palau" },
  { value: "ps", name: "Palestinian Territories" },
  { value: "pa", name: "Panama" },
  { value: "pg", name: "Papua New Guinea" },
  { value: "py", name: "Paraguay" },
  { value: "pe", name: "Peru" },
  { value: "ph", name: "Philippines" },
  { value: "pn", name: "Pitcairn Islands" },
  { value: "pl", name: "Poland" },
  { value: "pt", name: "Portugal" },
  { value: "pr", name: "Puerto Rico" },
  { value: "qa", name: "Qatar" },
  { value: "ro", name: "Romania" },
  { value: "ru", name: "Russia" },
  { value: "rw", name: "Rwanda" },
  { value: "re", name: "Réunion" },
  { value: "ws", name: "Samoa" },
  { value: "sm", name: "San Marino" },
  { value: "sa", name: "Saudi Arabia" },
  { value: "sn", name: "Senegal" },
  { value: "rs", name: "Serbia" },
  { value: "sc", name: "Seychelles" },
  { value: "sl", name: "Sierra Leone" },
  { value: "sg", name: "Singapore" },
  { value: "sk", name: "Slovakia" },
  { value: "si", name: "Slovenia" },
  { value: "sb", name: "Solomon Islands" },
  { value: "so", name: "Somalia" },
  { value: "za", name: "South Africa" },
  { value: "kr", name: "South Korea" },
  { value: "es", name: "Spain" },
  { value: "lk", name: "Sri Lanka" },
  { value: "sh", name: "St. Helena" },
  { value: "kn", name: "St. Kitts & Nevis" },
  { value: "lc", name: "St. Lucia" },
  { value: "pm", name: "St. Pierre & Miquelon" },
  { value: "vc", name: "St. Vincent & Grenadines" },
  { value: "sd", name: "Sudan" },
  { value: "sr", name: "Suriname" },
  { value: "sj", name: "Svalbard & Jan Mayen" },
  { value: "sz", name: "Swaziland" },
  { value: "se", name: "Sweden" },
  { value: "ch", name: "Switzerland" },
  { value: "sy", name: "Syria" },
  { value: "st", name: "São Tomé & Príncipe" },
  { value: "tw", name: "Taiwan" },
  { value: "tj", name: "Tajikistan" },
  { value: "tz", name: "Tanzania" },
  { value: "th", name: "Thailand" },
  { value: "tl", name: "Timor-Leste" },
  { value: "tg", name: "Togo" },
  { value: "tk", name: "Tokelau" },
  { value: "to", name: "Tonga" },
  { value: "tt", name: "Trinidad & Tobago" },
  { value: "tn", name: "Tunisia" },
  { value: "tr", name: "Turkey" },
  { value: "tm", name: "Turkmenistan" },
  { value: "tc", name: "Turks & Caicos Islands" },
  { value: "tv", name: "Tuvalu" },
  { value: "vi", name: "U.S. Virgin Islands" },
  { value: "ug", name: "Uganda" },
  { value: "ua", name: "Ukraine" },
  { value: "ae", name: "United Arab Emirates" },
  { value: "gb", name: "United Kingdom" },
  { value: "us", name: "United States" },
  { value: "uy", name: "Uruguay" },
  { value: "uz", name: "Uzbekistan" },
  { value: "vu", name: "Vanuatu" },
  { value: "va", name: "Vatican City" },
  { value: "ve", name: "Venezuela" },
  { value: "vn", name: "Vietnam" },
  { value: "wf", name: "Wallis & Futuna" },
  { value: "eh", name: "Western Sahara" },
  { value: "ye", name: "Yemen" },
  { value: "zm", name: "Zambia" },
  { value: "zw", name: "Zimbabwe" },
];

export const PLATFORMS = ["iOS", "Android", "Windows", "Other"];

//Map Pin Locations
export const PIN_LOCATION: { [key: string]: [number, number] } = {
  "THE UFO INN": [11, 47],
  "LONELY ROAD": [21, 74],
  "ROYAL FLUSH DAM": [13, 27],
  "SNAK EYES PARK": [39, 8],
  "RANCHO BANDITO": [71, 73],
  "AREA 52": [60, 24],
  "PAYDIRT MINES": [22, 11],
  STADIUM: [26, 45.5],
  "THE AIRPORT": [26, 63],
  "THE STRIP": [37, 55],
  "THE BUSINESS DISTRICT": [44, 49],
  "CITY HALL": [61, 56],
  "THE VILLAS": [70, 54],
  "INDUSTRY CITY": [60, 44],
  DOWNTOWN: [50, 30],
  "THE SHOPS": [40, 33],
  "CONSTRUCTION ZONE": [41, 24],
  "RED LIGHT DISTRICT": [30, 30],
};

//SNS profile picture links
export const PROFILE_PIC: { [key: string]: string } = {
  facebook: "https://scontent-sin6-2.xx.fbcdn.net/v/t1.0-9/89750914_2734102213311999_5104010242597847040_n.png?_nc_cat=109&_nc_sid=09cbfe&_nc_ohc=H38Own0ct0YAX-kp7MF&_nc_ht=scontent-sin6-2.xx&oh=aeeabd4617680e643b79e189771eb7f3&oe=5F22C9B9",
  youtube: "https://yt3.ggpht.com/a/AATXAJwx7dH1Pj0DDxR00DuQnigVjD3EDdhYWZ7CVJOQqQ=s100-c-k-c0xffffffff-no-rj-mo",
  instagram: "https://scontent-sin6-1.cdninstagram.com/v/t51.2885-19/s150x150/89342286_152214219258604_6975035869480615936_n.jpg?_nc_ht=scontent-sin6-1.cdninstagram.com&_nc_ohc=bfRQDc2d-b0AX_fZfEJ&oh=38259670896e22762850874a4ee7d30f&oe=5F28FAC0",
  discord: "https://cdn.discordapp.com/icons/638683471212052510/e135832e5518c5f18b327e28a37179c9.png",
};

export const highlights = [
  {
    index: "1", //unique ID
    image: "images/livefeed/update 35.jpg",
    title: "Update 35",
    link: "https://www.instagram.com/stories/highlights/17891785429411216/",
  },
  {
    index: "2",
    image: "images/livefeed/update 35.jpg",
    title: "Update 35.1",
    link: "https://www.instagram.com/stories/highlights/17853785134725946/",
  },
  {
    index: "3",
    image: "images/livefeed/update 34.jpg",
    title: "Update 34",
    link: "https://www.instagram.com/stories/highlights/17847930778554197/",
  },
  {
    index: "4",
    image: "images/livefeed/statistics.png",
    title: "Friday Reviews",
    link: "https://www.instagram.com/stories/highlights/17857472449478681/",
  },
  {
    index: "5",
    image: "images/livefeed/contest.png",
    title: "Update 33",
    link: "https://www.instagram.com/stories/highlights/18079408048078235/",
  },
  {
    index: "6",
    image: "images/livefeed/ask_us.png",
    title: "Take over",
    link: "https://www.instagram.com/stories/highlights/17885328559320994/",
  },
];

//Actions
export const mockActionCards = [
  {
    image: "images/action/action-new-level.jpg",
    title: "new level of open-world",
    description: "The renowned Gangstar Vegas, flagman of the series, brings intense open-world action on a new level!",
  },
  {
    image: "images/action/action-mission-is-calling.jpg",
    title: "Big Vegas Adventure",
    description: "Take part in a sandbox adventure across Vegas with buffed TPS and driving simulation",
  },
  {
    image: "images/action/action-big-vegas.jpg",
    title: "Mission is calling you",
    description: "Great story with lots of missions & practically infinite collection of any kind of fancied vehicles, weapons, clothes!",
  },
];

export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined && argument !== null;
}

//Handle Share
export const FACEBOOK_SHARE_LINK = "https://www.facebook.com/sharer.php?";
export const TWITTER_SHARE_LINK = "https://twitter.com/intent/tweet?";

export const generateShareLinkTo = (type: MediaType, content: string) => {
  if (content !== "") {
    switch (type) {
      case MediaType.Facebook:
        return FACEBOOK_SHARE_LINK + content;
      case MediaType.Twitter:
        return TWITTER_SHARE_LINK + content;
      default:
        return content;
    }
  }
  return content;
};

export const triggerPopup = (shareUrl: string) => {
  window.open(encodeURI(shareUrl), "", "width=400,height=400");
};

export { MediaType };

//Replace \n with line breaks
export const addLineBreak = (text: string) => {
  if (text) {
    text = text.replace("@here ", "");
    if (text.split("\n").length > -1) {
      return text.split("\n").map((pa, index) => {
        if (pa === "") {
          return <br key={index} />;
        } else if (pa.includes("||")) {
          return <p key={index}>{addLinks(pa)}</p>;
        } else if (pa.includes("http")) {
          return <p key={index}>{addHttpLink(pa)}</p>;
        }
        return <p key={index}>{pa}</p>;
      });
    }
    return <p>{text}</p>;
  }
  return text;
};

export const formatDate = (date: string) => {
  if (date) {
    let temp = new Date(date);
    return temp.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  }
  return date;
};

//Replace @ with links
const addLinks = (text: string) => {
  if (text.split("||").length > 2) {
    return text.split("||").map((a, index) => {
      if (a.indexOf("http") > -1 && a.split("@").length === 2) {
        return (
          <a key={index} href={a.split("@")[1]} target="_blank" rel="noopener noreferrer">
            {a.split("@")[0]}
          </a>
        );
      } else {
        return a;
      }
    });
  }
  return text;
};

const addHttpLink = (text: string) => {
  let match = text.match(/(https?:\/\/)([^ ]+)/g);
  let string = match ? match[0] : "";
  let texts = text.split(string);
  return (
    <React.Fragment>
      {texts[0]}
      <a target="_blank" rel="noopener noreferrer" href={string}>
        {string}
      </a>
      {texts[1] ? texts[1] : null}
    </React.Fragment>
  );
};

export const clampText = (text: string, maxLength: number, breakPoint: number = 0): string => {
  if (text && text.length >= maxLength) {
    if (breakPoint !== 0) {
      if (breakPoint > window.innerWidth) return text.slice(0, maxLength) + "...";
    } else {
      return text.slice(0, maxLength) + "...";
    }
  }
  return text;
};

export const mapNewsData = (data: any) => {
  if (data)
    return data.map((news: any) => ({
      title: news?.post_title,
      slug: news?.post_name,
      description: news?.short_desc,
      content: news?.post_content,
      date: news?.post_create,
      thumbnail: news?.thumbnail,
      image: news?.image,
      hotspot: news?.hotspot,
      video: news?.video_link,
      metaTitle: news?.meta_title,
      metaDescription: news?.meta_description,
    }));
  else return data;
};
